/*
 @Author: Company Name
 @URL: http://companyname.com
 This file contains the styling for the actual theme, this
 is the file you need to edit to change the look of the
 theme.
 However we used Less script to make things easy to maintain, this is the compressed ourput, we recommend to edit the less files and then recombile the files and add the result here, if you don't like to use less you can edit this file here.

 This files contents are outlined below.
 01.	Theme Reset Style
 02.	Global Elements
 03.	Menu
 04.	Social
 05.	Article
 06.	Posts
 07.	Responsive
 08.	Widgets
 09.	Flexslider
 10.	Grid
*/

/* =============================================
			Theme Reset Style
============================================= */

/*
font-family: 'Bai Jamjuree', sans-serif;
font-family: 'Lora', serif;
*/

@import url(https://fonts.googleapis.com/css?family=Bai+Jamjuree:400,500,600,700|Lora:400,700);
* {
	margin: 0px;
	padding: 0px;
}

body {
	overflow-x: hidden;
	background: #fff;
	color: rgba(0, 0, 0, 0.50);
	font: 500 14px/23px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
	color: #0f0f0f;
	margin: 0 0 8px;
	font-weight: 400;
	line-height: 1.5;
	font-style: normal;
	/* text-transform: capitalize; */
	font-family: 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

h1 {
	font-size: 48px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #0f0f0f;
}

p a,
p a:hover {
	text-decoration: underline;
}

p {
	margin: 0 0 20px;
	line-height: 20px;
	letter-spacing: normal;
}

a {
	text-decoration: none;
}

a:hover,
a:focus,
a:active {
	outline: none;
	text-decoration: none;
}

ul {
	margin: 0;
}

figure {
	margin-bottom: 6px;
	position: relative;
}

img {
	height: auto;
	max-width: 100%;
}

strong {
	color: #414141;
}

iframe {
	width: 100%;
	float: left;
	border: none;
}

.wp-caption,
.wp-caption-text,
.gallery-caption,
.bypostauthor {
}

.wp-caption.alignleft,
.wp-caption.alignnone {
	width: 100%;
}

button {
	border: none;
}

textarea,
select,
.tg-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	color: #666;
	outline: none;
	height: 40px;
	background: #fff;
	font-size: 14px;
	box-shadow: none;
	line-height: 18px;
	padding: 10px 20px;
	border-radius: 4px;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #dbdbdb;
	/* text-transform: capitalize; */
	box-sizing: border-box;
}

textarea.form-control {
	resize: none;
	height: 210px;
}

.form-control::-webkit-input-placeholder {
	color: #666;
}

/* WebKit, Blink, Edge */

.form-control:-moz-placeholder {
	color: #666;
}

/* Mozilla Firefox 4 to 18 */

.form-control::-moz-placeholder {
	color: #666;
}

/* Mozilla Firefox 19+ */

.form-control:-ms-input-placeholder {
	color: #666;
}

/* Internet Explorer 10-11 */

.form-control::-ms-input-placeholder {
	color: #666;
}

/* Microsoft Edge */

input:focus,
.select select:focus,
.form-control:focus {
	color: #666;
	border-color: #666;
	box-shadow: none !important;
}

.form-group {
	width: 100%;
	float: left;
	margin: 0 0 20px;
}

label {
	color: #666;
	display: block;
	font-weight: 400;
	margin-bottom: 10px;
}

form p {
	float: left;
	position: relative;
	width: 100%;
}

form p span i {
	top: 13px;
	left: 16px;
	color: #474747;
	position: absolute;
}

option {
	padding: 5px 15px;
}

li {
	line-height: 28px;
	/* list-style: disc inside none; */
}

ul ul {
	margin-left: 20px;
}

ol {
	float: none;
	list-style: decimal;
	padding-left: 15px;
}

ol ol {
	margin-left: 20px;
}

ol li {
	list-style: decimal;
	width: 100%;
}

table {
	background-color: transparent;
	max-width: 100%;
}

th {
	text-align: left;
}

table {
	width: 100%;
	margin-bottom: 15px;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border-top: 1px solid #dbdbdb;
	border: 1px solid #dbdbdb;
	line-height: 2.5;
	padding-left: 3px;
	text-align: center;
	vertical-align: top;
}

table thead tr th {
	border-top: 1px solid #dbdbdb;
	text-align: center;
	/* text-transform: capitalize; */
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 1px solid #dbdbdb;
}

table > thead > tr > th {
	border-bottom: 2px solid #dbdbdb;
	vertical-align: bottom;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
	border-bottom: 0;
}

table > tbody + tbody {
	border-top: 2px solid #dbdbdb;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 6px 10px;
}

p ins {
	color: #666;
}

dl dd {
	margin-left: 20px;
}

address {
	font-style: italic;
}

/* =============================================
			Theme Custom Select
============================================= */

.tg-select {
	color: #666;
	float: left;
	width: 100%;
	position: relative;
	text-transform: uppercase;
}

.tg-select:after {
	top: 0;
	right: 15px;
	z-index: 2;
	color: #666;
	display: block;
	content: '\f107';
	position: absolute;
	text-align: center;
	font-size: inherit;
	line-height: 40px;
	font-family: 'FontAwesome';
}

.tg-select select {
	z-index: 1;
	width: 100%;
	position: relative;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

.tg-select select option {
	color: #666;
}

/* =============================================
		Theme Custom CheckBox Radio
============================================= */

.tg-radio,
.tg-radio label,
.tg-checkbox,
.tg-checkbox label {
	margin: 0;
	width: 100%;
	float: left;
	position: relative;
}

.tg-radio label,
.tg-checkbox label {
	cursor: pointer;
	display: block;
	padding: 0 0 0 20px;
}

.tg-radio input[type=radio],
.tg-checkbox input[type=checkbox] {
	display: none;
}

.tg-radio input[type=radio] + label:before,
.tg-checkbox input[type=checkbox] + label:before {
	top: 4px;
	left: 0;
	color: #373542;
	font-size: 14px;
	line-height: 14px;
	content: '\f096';
	position: absolute;
	font-family: 'FontAwesome';
}

.tg-radio input[type=radio] + label:before {
	content: '';
}

.tg-radio input[type=radio]:checked + label:before,
.tg-checkbox input[type=checkbox]:checked + label:before {
	content: '\f046';
}

/* =============================================
			Theme Global Elements
============================================= */

.tg-haslayout {
	width: 100%;
	float: left;
}

.tg-sectionspace {
	padding: 50px 0;
}

.tg-locationmap {
	width: 100%;
	float: left;
	height: 500px;
}

.tg-btn {
	border: 0;
	color: #fff;
	padding: 0 50px;
	position: relative;
	text-align: center;
	border-radius: 25px;
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	font: 400 16px/50px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

.tg-smbtn {
	padding: 0 20px;
	line-height: 30px;
}

.tg-btn:hover {
	color: #fff;
}

.tg-btn-lg {
	width: 100%;
}

.tg-socialicons {
	overflow: hidden;
	list-style: none;
	text-align: center;
	line-height: 34px;
}

.tg-socialicons li {
	float: left;
	padding: 0 4px;
	line-height: inherit;
	list-style-type: none;
}

.tg-socialicons li a {
	width: 34px;
	height: 34px;
	color: #fff;
	display: block;
	background: #000;
	overflow: hidden;
	font-size: 13px;
	line-height: 34px;
	position: relative;
	text-align: center;
	border-radius: 34px;
	text-transform: capitalize;
}

.tg-inputwithicon.tg-facebook .tg-icon,
.tg-checkbox.tg-facebook,
li.tg-facebook a {
	background: #3b5999;
}

.tg-inputwithicon.tg-twitter .tg-icon,
.tg-checkbox.tg-twitter,
li.tg-twitter a {
	background: #55acee;
}

.tg-inputwithicon.tg-linkedin .tg-icon,
.tg-checkbox.tg-linkedin,
li.tg-linkedin a {
	background: #0077b5;
}

.tg-inputwithicon.tg-skype .tg-icon,
.tg-checkbox.tg-skype,
li.tg-skype a {
	background: #00aff0;
}

.tg-checkbox.tg-dropbox,
li.tg-dropbox a {
	background: #007ee5;
}

.tg-checkbox.tg-vimeo,
li.tg-vimeo a {
	background: #1ab7ea;
}

.tg-checkbox.tg-tumblr,
li.tg-tumblr a {
	background: #34465d;
}

.tg-checkbox.tg-yahoo,
li.tg-yahoo a {
	background: #410093;
}

.tg-inputwithicon.tg-googleplus .tg-icon,
.tg-checkbox.tg-googleplus,
li.tg-googleplus a {
	background: #dd4b39;
}

.tg-inputwithicon.tg-pinterestp .tg-icon,
.tg-checkbox.tg-pinterestp,
li.tg-pinterestp a {
	background: #bd081c;
}

.tg-checkbox.tg-youtube,
li.tg-youtube a {
	background: #cd201f;
}

.tg-checkbox.tg-stumbleupon,
li.tg-stumbleupon a {
	background: #eb4924;
}

.tg-checkbox.tg-reddit,
li.tg-reddit a {
	background: #ff5700;
}

.tg-checkbox.tg-quora,
li.tg-quora a {
	background: #b92b27;
}

.tg-checkbox.tg-yelp,
li.tg-yelp a {
	background: #af0606;
}

.tg-checkbox.tg-soundcloud,
li.tg-soundcloud a {
	background: #ff3300;
}

.tg-checkbox.tg-whatsapp,
li.tg-whatsapp a {
	background: #25d366;
}

.tg-checkbox.tg-wechat,
li.tg-wechat a {
	background: #09b83e;
}

.tg-checkbox.tg-instagram,
li.tg-instagram a {
	background: #e4405f;
}

.tg-checkbox.tg-dribbble,
li.tg-dribbble a {
	background: #ea4c89;
}

.tg-checkbox.tg-flickr,
li.tg-flickr a {
	background: #ff0084;
}

.tg-checkbox.tg-rss,
li.tg-rss a {
	background: #ff6600;
}

.tg-socialiconsborder li.tg-facebook a {
	color: #3b5999;
	border: 1px solid #3b5999;
}

.tg-socialiconsborder li.tg-twitter a {
	color: #55acee;
	border: 1px solid #55acee;
}

.tg-socialiconsborder li.tg-linkedin a {
	color: #0077b5;
	border: 1px solid #0077b5;
}

.tg-socialiconsborder li.tg-skype a {
	color: #00aff0;
	border: 1px solid #00aff0;
}

.tg-socialiconsborder li.tg-dropbox a {
	color: #007ee5;
	border: 1px solid #007ee5;
}

.tg-socialiconsborder li.tg-vimeo a {
	color: #1ab7ea;
	border: 1px solid #1ab7ea;
}

.tg-socialiconsborder li.tg-tumblr a {
	color: #34465d;
	border: 1px solid #34465d;
}

.tg-socialiconsborder li.tg-yahoo a {
	color: #410093;
	border: 1px solid #410093;
}

.tg-socialiconsborder li.tg-googleplus a {
	color: #dd4b39;
	border: 1px solid #dd4b39;
}

.tg-socialiconsborder li.tg-pinterestp a {
	color: #bd081c;
	border: 1px solid #bd081c;
}

.tg-socialiconsborder li.tg-youtube a {
	color: #cd201f;
	border: 1px solid #cd201f;
}

.tg-socialiconsborder li.tg-stumbleupon a {
	color: #eb4924;
	border: 1px solid #eb4924;
}

.tg-socialiconsborder li.tg-reddit a {
	color: #ff5700;
	border: 1px solid #ff5700;
}

.tg-socialiconsborder li.tg-quora a {
	color: #b92b27;
	border: 1px solid #b92b27;
}

.tg-socialiconsborder li.tg-yelp a {
	color: #af0606;
	border: 1px solid #af0606;
}

.tg-socialiconsborder li.tg-soundcloud a {
	color: #ff3300;
	border: 1px solid #ff3300;
}

.tg-socialiconsborder li.tg-whatsapp a {
	color: #25d366;
	border: 1px solid #25d366;
}

.tg-socialiconsborder li.tg-wechat a {
	color: #09b83e;
	border: 1px solid #09b83e;
}

.tg-socialiconsborder li.tg-instagram a {
	color: #e4405f;
	border: 1px solid #e4405f;
}

.tg-socialiconsborder li.tg-dribbble a {
	color: #ea4c89;
	border: 1px solid #ea4c89;
}

.tg-socialiconsborder li.tg-flickr a {
	color: #ff0084;
	border: 1px solid #ff0084;
}

.tg-socialiconsborder li.tg-rss a {
	color: #ff6600;
	border: 1px solid #ff6600;
}

.tg-socialiconsborder li a {
	background: none;
	line-height: 32px;
}

.tg-socialiconsborder li.tg-facebook a i {
	color: #3b5999;
}

.tg-socialiconsborder li.tg-twitter a i {
	color: #55acee;
}

.tg-socialiconsborder li.tg-linkedin a i {
	color: #0077b5;
}

.tg-socialiconsborder li.tg-skype a i {
	color: #00aff0;
}

.tg-socialiconsborder li.tg-dropbox a i {
	color: #007ee5;
}

.tg-socialiconsborder li.tg-vimeo a i {
	color: #1ab7ea;
}

.tg-socialiconsborder li.tg-tumblr a i {
	color: #34465d;
}

.tg-socialiconsborder li.tg-yahoo a i {
	color: #410093;
}

.tg-socialiconsborder li.tg-googleplus a i {
	color: #dd4b39;
}

.tg-socialiconsborder li.tg-pinterestp a i {
	color: #bd081c;
}

.tg-socialiconsborder li.tg-youtube a i {
	color: #cd201f;
}

.tg-socialiconsborder li.tg-stumbleupon a i {
	color: #eb4924;
}

.tg-socialiconsborder li.tg-reddit a i {
	color: #ff5700;
}

.tg-socialiconsborder li.tg-quora a i {
	color: #b92b27;
}

.tg-socialiconsborder li.tg-yelp a i {
	color: #af0606;
}

.tg-socialiconsborder li.tg-soundcloud a i {
	color: #ff3300;
}

.tg-socialiconsborder li.tg-whatsapp a i {
	color: #25d366;
}

.tg-socialiconsborder li.tg-wechat a i {
	color: #09b83e;
}

.tg-socialiconsborder li.tg-instagram a i {
	color: #e4405f;
}

.tg-socialiconsborder li.tg-dribbble a i {
	color: #ea4c89;
}

.tg-socialiconsborder li.tg-flickr a i {
	color: #ff0084;
}

.tg-socialiconsborder li.tg-rss a i {
	color: #ff6600;
}

.tg-socialicons li a i {
	color: #fff;
	display: block;
	text-align: center;
	font-size: inherit;
	line-height: inherit;
}

.tg-socialsharewithtext li a i {
	width: 34px;
}

.tg-socialicons li a:hover {
	color: #fff;
}

.tg-roundicontext .tg-usericonholder {
	width: 250px;
	display: block;
	overflow: hidden;
	font-style: normal;
}

.tg-roundicontext i,
.tg-roundicontext span {
	float: left;
	font-size: inherit;
	line-height: inherit;
}

.tg-roundicontext span {
	padding: 0 15px 0 0;
}

.tg-roundicontext:focus,
.tg-roundicontext:hover {
	color: #fff;
	width: 100%;
}

.tg-roundicontext:hover .tg-usericonholder {
	width: 100%;
}

.tg-verticalmiddle {
	margin: 0 -1px;
	float: none !important;
	display: inline-block;
	vertical-align: middle;
}

.tg-description {
	width: 100%;
	float: left;
}

.tg-bglight {
	background: #f7f8fa;
}

.tg-bgdark {
	background: #373542;
}

.tg-stars span,
.tg-stars {
	width: 100px;
	height: 16px;
	float: left;
	line-height: 16px;
	position: relative;
}

.tg-stars span:after,
.tg-stars:before {
	top: 0;
	left: 0;
	font-size: 16px;
	line-height: 16px;
	position: absolute;
	letter-spacing: 4px;
	font-family: 'FontAwesome';
	color: rgba(218, 218, 218, .8);
	content: '\f005 \f005 \f005 \f005 \f005';
}

.tg-stars span {
	width: 75%;
	overflow: hidden;
}

.tg-stars span:after {
	color: #000;
}

.row.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > [class^="col-"],
.no-gutters > [class*="col-"],
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.tg-dropdowarrow {
	top: 9px;
	right: 15px;
	color: #fff;
	width: 20px;
	height: 20px;
	display: none;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	position: absolute;
	text-align: center;
}

.tg-dropdowarrow i {
	display: block;
	font-size: inherit;
	line-height: inherit;
}

.tg-open .tg-dropdowarrow i:before {
	content: '\f106';
}

.menu-item-has-children,
.menu-item-has-mega-menu {
	position: relative;
}

.mega-menu-row {
	width: 100%;
	float: left;
	display: table;
	table-layout: fixed;
}

.mega-menu-col {
	display: table-cell;
	vertical-align: top;
}

.navbar-toggle {
	border: 0;
	margin: 3px 0;
	border-radius: 0;
	padding: 10px 12px;
}

.navbar-toggle .icon-bar {
	background: #fff;
}

.tg-formtheme {
	width: 100%;
	float: left;
}

.tg-formtheme fieldset {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	float: left;
	position: relative;
}

.tg-inputwithicon {
	position: relative;
}

.tg-inputwithicon i {
	top: 0;
	right: 0;
	z-index: 2;
	width: 40px;
	height: 40px;
	line-height: 40px;
	position: absolute;
	text-align: center;
}

.tg-inputwithicon .form-control {
	z-index: 1;
	position: relative;
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
	margin-bottom: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
	opacity: 0;
	visibility: hidden;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
	margin: 0;
	height: 3px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
	margin: 0;
	height: 3px;
	background: #f7f7f7;
}

/*==========================================================================================
		For Mobile Nav use this code in Responsive.css desired Query
==========================================================================================*/

/*
.navbar-toggle{display: block;}
.navbar-collapse.collapse {display: none!important;}
.collapse.in{display:block !important;}
*/

/*==========================================================================================
			For Mobile Nav use this code in Responsive.css desired Query
==========================================================================================*/

/* =============================================
			Theme Pagination Style
============================================= */

.tg-pagination {
	width: 100%;
	float: left;
	padding: 60px 0 0;
	text-align: center;
	font: 14px/30px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

.tg-pagination ul {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
	line-height: inherit;
	vertical-align: top;
	display: inline-block;
}

.tg-pagination ul li {
	float: left;
	margin: 0 6px;
	line-height: inherit;
	list-style-type: none;
}

.tg-pagination ul li a {
	width: 30px;
	display: block;
	color: #373542;
	text-align: center;
	position: relative;
	line-height: inherit;
}

.tg-pagination ul li:hover a,
.tg-pagination ul li.active a {
	color: #fff;
}

/* =============================================
			Theme BreadCrumb Style
============================================= */

.tg-breadcrumb {
	padding: 0;
	margin: 0;
	color: #fff;
	float: right;
	list-style: none;
	text-align: right;
	text-transform: uppercase;
	font: 14px/44px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

.tg-breadcrumb li {
	width: auto;
	list-style-type: none;
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	padding: 0 15px;
	position: relative;
}

.tg-breadcrumb li:after {
	top: 0;
	left: -3px;
	content: '-';
	position: absolute;
}

.tg-breadcrumb li:last-child {
	padding: 0 0 0 15px;
}

.tg-breadcrumb li:first-child:after {
	display: none;
}

.tg-breadcrumb li a {
	display: block;
	color: #fff;
}

/* =============================================
			Theme Styling
============================================= */

.tg-home {
}

.tg-wrapper {
	overflow-x: hidden;
}

.tg-main {
}

.tg-footer {
}

/* =============================================
			Header Style
============================================= */

.tg-header {
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	position: absolute;
	padding: 20px 70px 0;
}

.tg-logo {
	float: left;
}

.tg-logo a,
.tg-logo a img {
	display: block;
}

.tg-addnav {
	float: right;
	padding: 12px 0;
	font: 400 16px/38px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

.tg-addnav ul {
	width: 100%;
	float: left;
	list-style: none;
	font-size: inherit;
	line-height: inherit;
}

.tg-addnav ul li {
	float: left;
	padding: 0 20px;
	position: relative;
	font-size: inherit;
	line-height: inherit;
	list-style-type: none;
}

.tg-addnav ul li:last-child {
	padding-right: 0;
}

.tg-addnav ul li:before {
	left: 0;
	top: 12px;
	content: '';
	width: 1px;
	height: 16px;
	position: absolute;
	background: #acacac;
}

.tg-addnav ul li:last-child:before {
	display: none;
}

.tg-addnav ul li a {
	color: #231212;
	display: block;
}

.tg-addnav ul li a.tg-btn {
	color: #fff;
	padding: 0 20px;
	line-height: 40px;
	/* text-transform: capitalize; */
}

.tg-addnav ul li a i,
.tg-addnav ul li a span {
	font-size: inherit;
	line-height: inherit;
	display: inline-block;
	vertical-align: middle;
}

.tg-addnav ul li a span {
	opacity: 0.70;
}

/* =============================================
			Slider Style
============================================= */

.tg-home .tg-homeslider {
	height: 850px;
}

.tg-homeslider {
	height: 420px;
	overflow: hidden;
	position: relative;
	padding: 0 !important;
}

.tg-home .tg-homeslider .pogoSlider-slide {
	height: 100%;
	padding: 0 200px;
}

.tg-homeslider .pogoSlider-slide {
	height: 420px;
	padding: 50px 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.tg-home .tg-slidecontent {
	width: 550px;
	text-align: left;
}

.tg-slidecontent {
	width: 100%;
	float: left;
	text-align: center;
}

.tg-home .tg-slidecontent h1 {
	margin: 0;
}

.tg-slidecontent h1 {
	color: #353535;
	margin: 0 0 60px;
	font-size: 56px;
	font-weight: bold;
	line-height: 70px;
}

.tg-slidecontent .tg-description {
	padding: 50px 0;
}

.tg-slidecontent .tg-description p {
	margin: 0;
	color: #353535;
	font-size: 20px;
	line-height: 30px;
}

/* =============================================
			Navigation Style
============================================= */

.tg-nav {
	width: 100%;
	float: left;
	z-index: 10;
	background: #fff;
	position: relative;
	overflow: hidden;
	/* text-transform: capitalize; */
	font: 400 18px/24px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.10);
}

.tg-formsearch {
	top: -100%;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	padding: 34px;
	opacity: 0;
	visibility: hidden;
	background: #fff;
	position: absolute;
	transition: all 300ms ease-in-out;
}

.tg-formsearch .form-control {
	width: 100%;
	z-index: 1;
	border: 0;
	height: auto;
	font-size: 26px;
	padding-right: 82px;
	border-radius: 0;
	line-height: 40px;
	position: relative;
}

.tg-btnsearch,
.tg-btnclose {
	top: 0;
	right: 0;
	z-index: 2;
	width: 62px;
	height: 62px;
	line-height: 62px;
	color: #fa3b52;
	position: absolute;
	text-align: center;
	background: transparent;
}

.tg-btnclose span {
	display: block;
	line-height: inherit;
}

/* .tg-btnclose{
	top: -34px;
	right: -34px;
	width: 30px;
	height: 30px;
	color: #fff;
	background: #fa3b52;
} */

.tg-sticky .tg-formsearch {
	padding: 10px 34px;
}

.tg-formsearch.tg-showsearch {
	top: 0;
	opacity: 1;
	visibility: visible;
}

.tg-navigation {
	padding: 0;
	width: 100%;
	float: left;
	text-align: center;
}

.tg-navigation > ul {
	list-style: none;
	text-align: center;
	line-height: inherit;
	display: inline-block;
	vertical-align: middle;
}

.tg-navigation ul {
	list-style: none;
	line-height: inherit;
}

.tg-navigation > ul > li {
	float: left;
	padding: 0 25px;
	margin: 0 0 65px;
	position: relative;
}

.tg-navigation > ul > li:before,
.tg-navigation > ul > li:last-child:after {
	left: 0;
	top: 42px;
	content: '';
	width: 1px;
	height: 20px;
	position: absolute;
	background: #acacac;
}

.tg-navigation > ul > li:last-child:after {
	right: 0;
	left: auto;
}

.tg-navigation ul li {
	line-height: inherit;
	list-style-type: none;
}

.tg-navigation ul li a {
	display: block;
	color: #454545;
	position: relative;
	padding: 40px 15px 0;
}

.tg-navigation ul li a:before {
	top: 0;
	left: 0;
	width: 0;
	height: 2px;
	content: '';
	position: absolute;
}

.tg-navigation ul li a:after {
	left: 0;
	top: auto;
	width: 100%;
	bottom: -35px;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	position: absolute;
	content: attr(data-appcount);
	color: rgba(53, 53, 53, 0.50);
}

.tg-navigation ul li:hover a:before,
.tg-navigation ul li a:hover:before,
.tg-navigation ul li.tg-active a:before {
	width: 100%;
}

.tg-nav.tg-sticky {
	position: fixed;
}

.tg-nav.tg-sticky .tg-navigation > ul > li {
	margin: 0 0 45px;
}

.tg-nav.tg-sticky .tg-navigation > ul > li:before,
.tg-nav.tg-sticky .tg-navigation > ul > li:last-child:after {
	top: 18px;
}

.tg-nav.tg-sticky .tg-navigation ul li a {
	padding: 15px 15px 0;
}

.tg-nav.tg-sticky .tg-navigation ul li a:after {
	bottom: -28px;
}

.sub-menu,
.mega-menu {
	top: 150px;
	left: 0;
	opacity: 0;
	background: #fff;
	visibility: hidden;
	position: absolute;
}

.sub-menu {
	margin: 0;
	width: 230px;
}

.tg-navigation > ul > li:hover .sub-menu,
.tg-navigation > ul > li:hover .mega-menu {
	top: 100%;
	opacity: 1;
	visibility: visible;
}

.mega-menu-row {
	margin: 0;
	display: table;
	table-layout: fixed;
}

/* =============================================
			Main Style
============================================= */

.tg-nav.tg-sticky + .tg-main {
	padding: 189px 100px 91px;
}

.tg-main {
	margin: 0 0 -91px;
	padding: 60px 100px 91px;
}

/* =============================================
			App Grid Style
============================================= */

.tg-sectiontitle {
	width: 100%;
	float: left;
	padding: 0 15px 35px;
}

.tg-sectiontitle h2 {
	margin: 0;
	float: left;
	color: #353535;
	font-size: 30px;
	font-weight: 500;
	line-height: 30px;
}

.tg-sectiontitle h2 span {
	display: inline-block;
	vertical-align: middle;
}

.tg-btnviewmore {
	float: right;
	color: #353535;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	/* text-transform: capitalize; */
}

.tg-appsgrids {
	width: 100%;
	float: left;
	text-align: center;
}

.tg-app {
	width: 280px;
	padding: 15px;
	margin: 28px 0 0;
	display: inline-block;
	vertical-align: middle;
}

.tg-appholder {
	width: 100%;
	float: left;
	padding: 13px;
	text-align: left;
	background: #fff;
	border-radius: 8px;
	border: 1px solid #ccc;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
}

.tg-appimg {
	z-index: 2;
	width: 100%;
	float: left;
	padding: 3px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	margin: -40px 0 26px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
}

.tg-appimg a {
	display: block;
	overflow: hidden;
	border-radius: 5px;
}

.tg-appimg a img {
	width: 100%;
	height: auto;
	display: block;
}

.tg-appcontent {
	clear: both;
	overflow: hidden;
	margin: 0 -13px -13px;
}

.tg-apptag {
	float: left;
	color: #fff;
	padding: 0 20px;
	font-size: 16px;
	font-weight: 400;
	line-height: 28px;
	background: #d2d2d2;
	/* text-transform: capitalize; */
	border-radius: 0 15px 15px 0;
}

.tg-apprating {
	float: right;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px;
	padding: 0 13px 0 0;
	/* text-transform: capitalize; */
	color: rgba(53, 53, 53, 0.50);
}

.tg-apprating i {
	float: left;
	line-height: 16px;
	line-height: 28px;
	font-style: normal;
}

.tg-apprating i:first-child {
	color: #ffd104;
	font-size: 14px;
	margin: 0 7px 0 0;
}

.tg-apptitle {
	width: 100%;
	float: left;
	padding: 25px 13px 15px;
}

.tg-apptitle h3 {
	margin: 0;
	color: #353535;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
}

.tg-apptitle h3 a {
	width: 100%;
	float: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.tg-appcontent .tg-description {
	padding: 0 13px 20px;
}

.tg-appcontent .tg-description p {
	margin: 0;
	width: 100%;
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.tg-btnaddtowishlist {
	width: 100%;
	float: left;
	font-size: 14px;
	line-height: 52px;
	text-align: center;
	border-radius: 0 0 8px 8px;
	color: rgba(0, 0, 0, 0.80);
	background: #fff url('../images/bgbtn.jpg') repeat-x;
}

.tg-appholder:hover .tg-apptag {
}

/* =============================================
			App Grid Views Style
============================================= */

.tg-appsgridsvtwo .tg-app,
.tg-appsgridsvthree .tg-app,
.tg-appsgridsvfive .tg-app,
.tg-appsgridsvsix .tg-app,
.tg-appsgridsvseven .tg-app {
	margin: 0 0 18px;
}

.tg-appsgridsvtwo .tg-appholder,
.tg-appsgridsvthree .tg-appholder,
.tg-appsgridsvfive .tg-appholder,
.tg-appsgridsvsix .tg-appholder,
.tg-appsgridsvseven .tg-appholder {
	border: 0;
	padding: 8px;
	border-radius: 3px;
	text-align: center;
}

.tg-appsgridsvtwo .tg-appimg,
.tg-appsgridsvthree .tg-appimg,
.tg-appsgridsvfive .tg-appimg,
.tg-appsgridsvsix .tg-appimg {
	padding: 0;
	margin-top: 0;
	border-radius: 0;
}

.tg-appsgridsvtwo .tg-appimg a,
.tg-appsgridsvthree .tg-appimg a,
.tg-appsgridsvfive .tg-appimg a,
.tg-appsgridsvsix .tg-appimg a,
.tg-appsgridsvseven .tg-appimg a {
	border-radius: 0;
}

.tg-appsgridsvtwo .tg-apptitle,
.tg-appsgridsvthree .tg-apptitle,
.tg-appsgridsvfive .tg-apptitle,
.tg-appsgridsvsix .tg-apptitle,
.tg-appsgridsvseven .tg-apptitle {
	padding-top: 0;
}

.tg-appsgridsvtwo .tg-appcontent,
.tg-appsgridsvthree .tg-appcontent,
.tg-appsgridsvfive .tg-appcontent,
.tg-appsgridsvsix .tg-appcontent,
.tg-appsgridsvseven .tg-appcontent {
	text-align: center;
	margin: 0 -8px -3px;
}

.tg-appsgridsvtwo .tg-apptag,
.tg-appsgridsvthree .tg-apptag,
.tg-appsgridsvfive .tg-apptag,
.tg-appsgridsvsix .tg-apptag,
.tg-appsgridsvseven .tg-apptag {
	padding: 0;
	width: 100%;
	margin: 0 auto;
	border-radius: 0;
	line-height: 20px;
	background: transparent;
	color: rgba(53, 53, 53, 0.70);
}

.tg-appsgridsvtwo .tg-apprating,
.tg-appsgridsvthree .tg-apprating,
.tg-appsgridsvfive .tg-apprating,
.tg-appsgridsvsix .tg-apprating,
.tg-appsgridsvseven .tg-apprating {
	width: 100%;
	float: left;
	line-height: 14px;
	padding: 15px 0 20px;
}

.tg-appsgridsvtwo .tg-apprating i,
.tg-appsgridsvthree .tg-apprating i,
.tg-appsgridsvfive .tg-apprating i,
.tg-appsgridsvsix .tg-apprating i,
.tg-appsgridsvseven .tg-apprating i {
	margin: 0;
	float: none;
	color: #ffd104;
	font-size: 14px;
	line-height: 14px;
	display: inline-block;
	vertical-align: middle;
}

.tg-appsgridsvtwo .tg-smbtn,
.tg-appsgridsvthree .tg-smbtn,
.tg-appsgridsvsix .tg-smbtn,
.tg-appsgridsvseven .tg-smbtn {
	float: none;
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 -36px;
	line-height: 36px;
	background: #bcbcbc;
	text-transform: none;
}

.tg-appsgridsvtwo .tg-appholder:hover .tg-apptag,
.tg-appsgridsvthree .tg-appholder:hover .tg-apptag,
.tg-appsgridsvfive .tg-appholder:hover .tg-apptag,
.tg-appsgridsvsix .tg-appholder:hover .tg-apptag,
.tg-appsgridsvseven .tg-appholder:hover .tg-apptag {
	background: transparent;
}

.tg-apphalfgrid {
	width: 50%;
	float: left;
}

.tg-apphalfgrid:nth-child(odd) {
	padding: 0 50px 0 0;
}

.tg-apphalfgrid:nth-child(even) {
	padding: 0 0 0 50px;
}

.tg-apphalfgrid .tg-app {
	float: left;
	width: 33.33%;
}

.tg-appsgridsvthree .tg-btnviewmore {
	width: 100%;
	float: left;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	line-height: 62px;
	margin: 15px 0 0;
	background: #01366c;
}

.tg-largesingleapp {
	float: left;
	margin: 0 50px 0 0;
	width: calc(44% - 50px);
}

.tg-largesingleapp + .tg-apphalfgrid {
	width: auto;
	float: none;
	padding: 0 4px;
	overflow: hidden;
}

.tg-largesingleapp + .tg-apphalfgrid .tg-app {
	float: left;
	width: 25%;
}

.tg-largesingleapp figure {
	margin: 0;
	width: 100%;
	float: left;
	background: #000;
	position: relative;
}

.tg-largesingleapp figure a {
	display: block;
	opacity: 0.50;
}

.tg-largesingleapp figure a img {
	width: 100%;
	height: auto;
	display: block;
}

.tg-largesingleapp figure figcaption {
	left: 0;
	bottom: 0;
	z-index: 2;
	color: #fff;
	width: 100%;
	padding: 28px;
	font-size: 26px;
	font-weight: 500;
	line-height: 30px;
	position: absolute;
	text-align: center;
}

.tg-appsgridsvfour .tg-appimg:before {
	top: 0;
	left: 0;
	width: 4px;
	z-index: -1;
	content: '';
	height: 100%;
	position: absolute;
	border-radius: 6px;
	transform: rotate(45deg) translateX(30%) translateY(-30%);
}

.tg-appsgridsvfive {
}

.tg-appsgridsvsix .tg-app,
.tg-appsgridsvseven .tg-app {
	/* width: 340px; */
}

.tg-appsgridsvseven .tg-appholder {
	padding: 0;
	box-shadow: none;
	background: transparent;
}

.tg-appsgridsvsix .tg-appimg,
.tg-appsgridsvseven .tg-appimg {
	margin: 0;
}

.tg-appsgridsvsix .tg-appcontent,
.tg-appsgridsvseven .tg-appcontent {
	text-align: left;
}

.tg-appsgridsvsix .tg-apptitle,
.tg-appsgridsvseven .tg-apptitle {
	padding: 25px 8px 15px;
}

.tg-clientnameandrating {
	float: left;
	text-align: left;
	padding: 0 0 5px 8px;
}

.tg-clientnameandrating .tg-apptag,
.tg-clientnameandrating .tg-apprating {
	width: auto;
	float: none;
	display: block;
}

.tg-clientnameandrating .tg-apprating i {
}

.tg-appsgridsvsix .tg-smbtn,
.tg-appsgridsvseven .tg-smbtn {
	float: right;
	padding: 0 15px;
	font-size: 14px;
	line-height: 30px;
	border-radius: 15px;
	background: #01366c;
	margin: 21px 8px 0 0;
}

.tg-clientnameandrating .tg-apprating {
	padding-bottom: 0;
}

.tg-appsgridsvseven .tg-appimg {
	border-radius: 0;
	margin: 0 0 0 10px;
	width: calc(100% - 10px);
	padding: 10px 10px 10px 0;
}

.tg-appsgridsvseven .tg-appimg a {
	width: 240px;
	right: 10px;
	position: relative;
}

/* .tg-appsgridsvseven .tg-appimg a img{width: calc(220px + 20px;} */

/* =============================================
			App Detail Page Style
============================================= */

.tg-twocolumns {
	width: 100%;
	float: left;
}

.tg-content {
	width: 100%;
	float: left;
}

.tg-appdetail {
	margin: 0;
	padding: 0;
	width: 100%;
}

.tg-apphead {
	width: 100%;
	float: left;
	padding: 40px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.10);
}

.tg-apphead figure {
	margin: 0;
	float: left;
	overflow: hidden;
	border-radius: 7px;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.10);
}

.tg-apphead figure img {
	display: block;
}

.tg-appheadcontent {
	overflow: hidden;
	padding: 0 0 0 60px;
}

.tg-appheadcontent .tg-apptitle {
	margin: 0;
	padding: 0;
}

.tg-appheadcontent .tg-apptitle h2 {
	margin: 0;
	float: left;
	color: #353535;
	font-size: 30px;
	font-weight: 500;
	line-height: 33px;
}

.tg-appheadcontent .tg-apptitle {
}

.tg-like {
	color: #555;
	float: right;
	padding: 3px 0;
}

.tg-like i {
	display: block;
	font-size: 26px;
	line-height: 26px;
}

.tg-appratingandcounts {
	width: 100%;
	float: left;
	padding: 15px 0 30px;
	text-align: left;
}

.tg-appratingandcounts span {
	font-size: 16px;
	line-height: 20px;
	display: inline-block;
	vertical-align: middle;
}

.tg-appratingandcounts span + span {
	padding: 0 0 0 20px;
}

.tg-appratingandcounts span i {
	float: left;
	color: #ffd104;
	font-size: 14px;
	line-height: 14px;
}

.tg-appratingandcounts span i + i {
	margin: 0 0 0 4px;
}

.tg-shortdetail {
	width: 100%;
	float: left;
	color: #787878;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.tg-shortdetail p {
	margin: 0;
	font-size: inherit;
	line-height: inherit;
}

.tg-shortdetail p + p {
	margin: 5px 0 0;
}

.tg-wishlistanddownloadbtn {
	width: 100%;
	float: left;
	padding: 20px 0 0;
}

.tg-wishlistanddownloadbtn .tg-btnaddtowishlist {
	width: auto;
	color: #000;
	margin: 10px 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 30px;
	background: transparent;
}

.tg-btnaddtowishlist i,
.tg-btnaddtowishlist span {
	float: left;
	font-size: inherit;
	line-height: inherit;
}

.tg-btnaddtowishlist i {
	color: #fff;
	width: 30px;
	font-size: 14px;
	overflow: hidden;
	margin: 0 15px 0 0;
	border-radius: 15px;
}

.tg-wishlistanddownloadbtn .tg-btn {
	float: right;
}

.tg-appbody {
	width: 100%;
	float: left;
	padding: 40px;
	color: #787878;
	font-size: 16px;
	line-height: 34px;
	background: #fff;
	margin: 30px 0 0;
	border-radius: 10px;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.10);
}

.tg-appimggallery {
	width: 100%;
	float: left;
	margin: 0 0 60px;
}

.tg-appimggallery ul {
	width: 100%;
	float: left;
	list-style: none;
}

.tg-appimggallery ul li {
	float: left;
	list-style-type: none;
}

.tg-appimggallery ul li + li {
	margin: 0 0 0 30px;
}

.tg-appimggallery ul li figure {
	margin: 0;
	padding: 5px;
	background: #fff;
	border-radius: 5px;
	vertical-align: top;
	display: inline-block;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
}

.tg-appimggallery ul li figure img {
	display: block;
	border-radius: 5px;
}

.tg-appdetaildescription {
	width: 100%;
	float: left;
	padding: 0 0 100px;
}

.tg-appdetaildescription p {
	margin: 0 0 60px;
	line-height: 34px;
}

.tg-appdetaildescription h3 {
	color: #262626;
	margin: 0 0 20px;
	font-weight: 500;
	line-height: 22px;
}

.tg-appdetaildescription ul {
	list-style: none;
	line-height: inherit;
}

.tg-appdetaildescription ul li {
	margin: 0 0 15px;
	line-height: inherit;
}

.tg-appdetaildescription ul li:last-child {
	margin: 0;
}

.tg-appreviewsarea {
	width: 100%;
	float: left;
}

.tg-appreviewshead {
	z-index: 2;
	width: 100%;
	float: left;
	margin: 0 0 60px;
	position: relative;
}

.tg-appreviewshead:before {
	left: 0;
	top: 50%;
	z-index: -1;
	height: 1px;
	content: '';
	position: absolute;
	background: #d0d0d0;
	width: calc(100% - 40px);
}

.tg-appreviewshead h4 {
	margin: 0;
	float: left;
	color: #262626;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	background: #fff;
	padding: 0 15px 0 0;
	text-transform: uppercase;
}

.tg-appreviewshead span {
	color: #fff;
	float: right;
	padding: 0 5px;
	font-size: 14px;
	line-height: 25px;
	border-radius: 25px;
}

.tg-appreviews {
	width: 100%;
	float: left;
	color: #787878;
	font-size: 18px;
	line-height: 34px;
	list-style: none;
}

.tg-appreviews li {
	width: 100%;
	float: left;
	position: relative;
	font-size: inherit;
	line-height: inherit;
	list-style-type: none;
}

.tg-appreviews li:before {
	top: 0;
	left: 27px;
	width: 1px;
	content: '';
	height: 100%;
	position: absolute;
	background: #d0d0d0;
}

.tg-appreviews li:last-child:before {
	display: none;
}

.tg-appreview {
	width: 100%;
	float: left;
	padding: 0 0 50px;
}

.tg-appreviews li:last-child .tg-appreview {
	padding: 0;
}

.tg-appreview figure {
	margin: 0;
	float: left;
	overflow: hidden;
	border-radius: 5px;
}

.tg-appreviewcontent {
	overflow: hidden;
	padding: 0 0 0 50px;
}

.tg-appreviewcontent h5 {
	margin: 0;
	float: left;
	color: #454545;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.tg-appreviewcontent .tg-like {
	padding: 0;
}

.tg-like i {
	float: left;
	color: #454545;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	font-style: normal;
}

.tg-like i:first-child {
	color: #aaa;
	margin: 0 15px 0 0;
}

.tg-appreviewcontent .tg-appratingandcounts {
	padding: 15px 0;
}

.tg-appratingandcounts time {
	color: #787878;
	font-size: 16px;
	line-height: 20px;
	padding: 0 10px 0 0;
	display: inline-block;
	vertical-align: middle;
}

.tg-appreviewcontent .tg-description {
	font-size: 16px;
	line-height: 34px;
}

.tg-appreviewcontent .tg-description p {
	margin: 0;
	font-size: inherit;
	line-height: inherit;
}

.tg-sidebar {
	width: 100%;
	float: left;
}

.tg-appwidget {
	width: 100%;
	float: left;
	padding: 15px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.10);
}

.tg-appwidget + .tg-appwidget {
	margin: 10px 0 0;
}

.tg-appwidget .tg-app {
	width: 100%;
}

/* =============================================
			Sidebar Widget Style
============================================= */

.tg-widget {
	width: 100%;
	float: left;
	box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.10);
}

.tg-widget + .tg-widget {
	margin: 30px 0 0;
}

.tg-widgettitle {
	width: 100%;
	float: left;
	border-radius: 6px 6px 0 0;
	padding: 5px 15px 8px;
}

.tg-widgettitle h3 {
	margin: 0;
	color: #fff;
	font-size: 20px;
	line-height: 32px;
}

.tg-widgetcontent {
	width: 100%;
	float: left;
	padding: 15px;
	border-radius: 0 0 6px 6px;
	border: 1px solid #ccc;
	border-top: 0;
}

.tg-widgetcontent ul {
	margin: -5px;
	overflow: hidden;
	list-style: none;
}

.tg-widgetcontent ul li {
	float: left;
	padding: 5px;
	list-style-type: none;
}

.tg-widgetcontent ul li a {
	text-align: center;
	padding: 1rem 3rem;
}

.tg-widgetcontent ul li.tg-btnreset {
	width: 100%;
}

.tg-widgetcontent ul li.tg-btnreset a {
	margin: 0;
	color: #fff;
	background: #353535;
}

/* =============================================
			Footer Style
============================================= */

.tg-footer {
	width: 100%;
	float: left;
	padding: 190px 0 0;
	background: url('../images/bgfooter.png') no-repeat 0 0;
	background-size: cover;
}

.tg-footerupcontent {
	width: 100%;
	margin: 0 auto;
	max-width: 1000px;
	overflow: hidden;
	text-align: center;
}

.tg-footerupcontent h2 {
	color: #353535;
	font-size: 22px;
	font-weight: 400;
	margin: 0 0 16px;
	line-height: 22px;
}

.tg-footerupcontent h3 {
	color: #fff;
	font-size: 36px;
	margin: 0 0 43px;
	font-weight: 600;
	line-height: 53px;
}

.tg-formnewsletter {
	margin: 0 0 40px;
}

.tg-formnewsletter fieldset {
	position: relative;
	padding: 0;
}

.tg-formnewsletter fieldset .form-control {
	border: 0;
	z-index: 1;
	height: 52px;
	line-height: 22px;
	position: relative;
	border-radius: 26px;
	padding: 15px 26px;
}

.tg-formnewsletter fieldset textarea.form-control {
	/* height: 134px;
	border-radius: 16px; */
}

.tg-formnewsletter fieldset button {
	width: 100%;
	padding: 0 30px;
	line-height: 52px;
	border-radius: 52px;
}

.tg-formnewsletter fieldset .row > div:last-child {
	text-align: center;
}

.tg-fnav {
	width: 100%;
	float: left;
	padding: 0 0 70px;
	text-align: center;
	font: 500 18px/24px 'Bai Jamjuree', Arial, Helvetica, sans-serif;
}

.tg-fnav ul {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
}

.tg-fnav ul li {
	float: left;
	padding: 0 30px;
	line-height: inherit;
	list-style-type: none;
}

.tg-fnav ul li a {
	color: #454545;
	display: block;
}

.tg-footerbar {
	width: 100%;
	float: left;
	padding: 7px 100px;
	background: #01366c;
	border-top: 2px solid #489669;
}

.tg-copyright {
	color: #fff;
	float: left;
	padding: 14px 0;
	font-size: 14px;
	font-weight: 500;
	line-height: 30px;
}

.tg-footerbar .tg-socialicons {
	width: auto;
	float: right;
	padding: 12px 0;
}

.tg-footerbar .tg-socialicons li {
	padding: 0 0 0 20px;
}

/* =============================================
			Signup Page Style
============================================= */

.tg-scene {
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	list-style: none;
	position: absolute;
}

.tg-scene li {
	top: 0;
	left: 0;
	width: 100%;
	position: absolute;
}

.tg-fullscreen {
	width: 100%;
	float: left;
	height: 100vh;
	overflow: hidden;
	position: relative;
}

.tg-fullscreen .tg-main {
	padding: 0;
	z-index: 2;
	height: 100vh;
	min-height: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.tg-formarea {
	z-index: 2;
	max-width: 530px;
}

.tg-formarea {
}

.tg-formarea .tg-formtheme {
	padding: 45px;
	position: relative;
	border-radius: 10px;
	border: 1px solid #dcdcdc;
	box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.10);
	background: #fff url('../images/img-01.png') no-repeat;
}

.tg-formarea .tg-formtheme:before {
	top: 0;
	z-index: -1;
	left: 350px;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
}

.tg-formarea .tg-formsignup:before {
	background: url('../images/banner/signup.jpg') no-repeat 0 50%;
}

.tg-formarea .tg-formlogin:before {
	/* top: 110px; */
	left: 490px;
	width: 320px;
	background: url('../images/banner/login.png') no-repeat 0 100%;
	background-size: auto 100%;
}

.tg-formarea .tg-formtheme h2 {
	margin: 0;
	color: #262626;
	font-size: 26px;
	font-weight: 500;
	line-height: 26px;
	text-align: center;
	/* text-transform: capitalize; */
}

.tg-formarea .tg-formtheme fieldset {
	padding: 110px 0 0;
}

.tg-formarea .tg-formtheme .form-group {
	margin: 0 0 26px;
}

.tg-formlogin .form-group ul,
.tg-formsignup .form-group ul {
	text-align: center;
}

.tg-formlogin .form-group ul li,
.tg-formsignup .form-group ul li {
	float: none;
	display: inline-block;
	vertical-align: top;
}

.tg-formarea .tg-formtheme .form-group:last-child {
	margin: 0;
	text-align: center;
}

.tg-formarea .tg-formtheme .form-control {
	border: 0;
	height: 52px;
	line-height: 22px;
	padding: 15px 25px;
	background: #f0f0f0;
	border-radius: 52px;
}

.tg-formarea .tg-description {
	padding: 50px 0 0;
}

.tg-formarea .tg-description p {
	color: #757575;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
}

.tg-formarea .tg-description p a {
	text-decoration: none;
}

.tg-formarea .tg-formlogin .form-group:nth-child(3) {
	text-align: center;
}

.tg-formarea .tg-formlogin .form-group:last-child {
	margin: 0 0 30px;
}

/* =============================================
			DashBoard Style
============================================= */

.tg-dashboard .tg-verticalscrollbar {
	width: 100%;
	float: left;
	height: 100%;
	position: relative;
}

.tg-dashboard .tg-verticalscrollbar .mCustomScrollBox {
}

.tg-dashboard .tg-verticalscrollbar .mCSB_inside > .mCSB_container {
	margin: 0;
}

.tg-dashboard .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	margin: 0;
	border-radius: 0;
}

.tg-dashboard .mCSB_scrollTools {
	width: 4px;
}

.tg-dashboard .mCSB_scrollTools .mCSB_draggerRail {
	margin: 0;
	width: 4px;
	border-radius: 0;
	background: transparent;
}

.tg-dashboard {
	width: 100%;
	float: left;
}

.tg-dashboard .tg-sectionspace {
	padding: 10px 0;
}

.tg-dashboard .tg-toppaddingzero {
	padding-top: 0;
}

.tg-dashboard .tg-bottompaddingzero {
	padding-bottom: 0;
}

.tg-dashboard .tg-sectiontitle {
	padding: 0 15px 9px;
}

/* .tg-dashboard */

.tg-header {
	top: 0;
	left: 0;
	width: 100%;
	float: left;
	min-height: 96px;
	padding: 20px 30px;
	position: absolute;
}

.tg-dbaddnav {
	color: #fff;
	float: right;
	font-size: 18px;
	font-weight: 500;
	line-height: 35px;
}

.tg-dbaddnav > ul {
	width: 100%;
	float: left;
	list-style: none;
}

.tg-dbaddnav > ul > li {
	font-size: inherit;
	line-height: inherit;
	list-style-type: none;
	display: inline-block;
	vertical-align: middle;
}

.tg-dbaddnav > ul > li + li {
	padding: 0 0 0 40px;
}

.tg-dbaddnav > ul > li > a {
	color: #fff;
	display: block;
}

.tg-dbaddnav > ul > li > a i,
.tg-dbaddnav > ul > li > a i svg {
	display: block;
}

.at-dropdown {
	width: 100%;
	float: left;
}

.at-btndropdown {
	width: 100%;
	float: left;
	outline: 0;
	font-size: 18px;
	line-height: 35px;
	background: none;
}

.at-btndropdown figure,
.at-btndropdown span,
.at-btndropdown i {
	margin: 0;
	display: inline-block;
	vertical-align: middle;
}

.at-btndropdown figure {
	overflow: hidden;
	border-radius: 50%;
	border: 3px solid rgba(255, 255, 255, 0.50);
}

.at-btndropdown figure img {
	display: block;
}

.at-btndropdown span {
	padding: 0 15px;
}

.at-btndropdown i {
}

.at-dropdownmenu {
	right: 0;
	left: auto;
	top: 150px;
	border: 0;
	padding: 0;
	opacity: 0;
	display: block;
	min-width: 200px;
	margin: 10px 0 0;
	font-size: 16px;
	line-height: 26px;
	list-style: none;
	border-radius: 0;
	visibility: hidden;
	transition: all 300ms ease-in-out;
}

.at-dropdownmenu li {
	width: 100%;
	float: left;
	font-size: inherit;
	line-height: inherit;
	list-style-type: none;
}

.at-dropdownmenu li + li {
	border-top: 1px solid #ddd;
}

.at-dropdownmenu li a {
	display: block;
	padding: 8px 20px;
}

.at-dropdown.open .at-dropdownmenu {
	top: 100%;
	opacity: 1;
	visibility: visible;
}

.tg-main.tg-dashboard {
	margin: 0;
	z-index: 1;
	width: 100%;
	float: left;
	overflow: hidden;
	position: relative;
	padding: 84px 30px 30px;
}

.tg-main.tg-dashboard:before {
	top: 0;
	left: 0;
	z-index: -1;
	content: '';
	width: 100%;
	height: 170px;
	position: absolute;
	background: #55bef8;
}

.tg-main.tg-dashboard:after {
	top: 0;
	right: -60px;
	z-index: -1;
	content: '';
	width: 200px;
	height: 170px;
	position: absolute;
	background: #00e9c2;
	transform: skewX(30deg);
}

.tg-dashboardcontent {
	width: 100%;
	float: left;
	padding: 0 300px;
	position: relative;
	min-height: 781px;
}

.tg-leftsidebar {
	left: 0;
	top: 40px;
	z-index: 1;
	width: 300px;
	position: absolute;
	height: calc(100% - 40px);
}

.tg-hideleftsidebar .tg-dashboardcontent {
	padding-left: 0;
}

.tg-hideleftsidebar .tg-btntoggleleftsidebar {
	margin: -65px 0 0;
	transform: rotate(-180deg);
	transform-origin: center center;
}

.tg-dbnav {
	width: 100%;
	float: left;
	padding: 40px 0;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
	margin: 0 0 30px;
	background: #f9f9f9;
	text-transform: capitalize;
	border-radius: 10px 0 0 10px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
}

.tg-dbnav ul {
	width: 100%;
	float: left;
	list-style: none;
}

.tg-dbnav ul li {
	width: 50%;
	margin: 0 -2px;
	font-size: inherit;
	line-height: inherit;
	vertical-align: top;
	display: inline-block;
	list-style-type: none;
}

.tg-dbnav ul li a {
	width: 100%;
	float: left;
	color: #353535;
	text-align: center;
}

.tg-dbnav ul li a i {
	display: inline-block;
	vertical-align: middle;
}

.tg-dbnav ul li:nth-child(1),
.tg-dbnav ul li:nth-child(2) {
	padding: 0 0 20px;
}

.tg-dbnav ul li:nth-child(3),
.tg-dbnav ul li:nth-child(4) {
	padding: 20px 0 0;
}

.tg-dbnav ul li:nth-child(1) i {
	padding: 11px 0 0;
}

.tg-dbnav ul li:nth-child(2) i {
	padding: 13px 0 0;
}

.tg-dbnav ul li:nth-child(3) i {
	padding: 6px 0 0;
}

.tg-dbnav ul li a i svg {
	display: block;
}

.tg-dbnav ul li a span {
	display: block;
	padding: 10px 0 0;
}

.tg-counters {
	width: 100%;
	float: left;
	color: #353535;
	font-size: 17px;
	font-weight: 500;
	line-height: 20px;
	background: #f9f9f9;
	text-transform: capitalize;
	border-radius: 10px 0 0 10px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
}

.tg-counter {
	width: 100%;
	float: left;
	padding: 54px 30px;
}

.tg-counter + .tg-counter {
	border-top: 1px solid #e1e1e1;
}

.tg-counter i {
	float: left;
	padding: 22px 0 0;
}

.tg-counter i svg {
	display: block;
}

.tg-counterbox {
	color: #4f4f4f;
	font-size: 18px;
	line-height: 22px;
	overflow: hidden;
	padding: 0 0 0 20px;
}

.tg-counterbox h4 {
	margin: 0;
	color: #262626;
	font-size: 40px;
	line-height: 43px;
}

.tg-counterbox h5 {
	color: #4f4f4f;
	margin: 10px 0 0;
	font-size: 18px;
	line-height: 22px;
}

.tg-leftsidebarscroll .mCSB_scrollTools .mCSB_draggerRail {
	background: none;
}

.tg-rightsidebar {
	right: 0;
	top: 40px;
	z-index: 1;
	width: 300px;
	padding: 40px 0;
	position: absolute;
	background: #f9f9f9;
	height: calc(100% - 40px);
	border-radius: 0 10px 10px 0;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.10);
}

.tg-hiderightsidebar .tg-dashboardcontent {
	padding-right: 0;
}

.tg-hiderightsidebar .tg-btntogglerightsidebar {
	margin: -65px 0 0;
	transform: rotate(-180deg);
	transform-origin: center center;
}

.tg-dashboardcontent .tg-content {
	z-index: 2;
	padding: 40px 25px;
	/* overflow: hidden; */
	position: relative;
	background: #f9f9f9;
	/* height: calc(100vh - 114px); */
	border-radius: 10px 10px 0 0;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.10);
}

.tg-app {
	width: 238px;
}

.tg-userslist {
	width: 100%;
	float: left;
	color: #777;
	font-size: 14px;
	line-height: 17px;
	list-style: none;
}

.tg-userslist li {
	width: 100%;
	float: left;
	font-size: inherit;
	line-height: inherit;
	list-style-type: none;
}

.tg-userslist li + li {
	border-top: 1px solid #ebebeb;
}

.tg-user {
	width: 100%;
	float: left;
	padding: 15px 25px;
}

.tg-userslist li:first-child .tg-user {
	padding-top: 0;
}

.tg-userslist li:last-child .tg-user {
	padding-bottom: 0;
}

.tg-user figure {
	float: left;
	margin: 0 15px 0 0;
	border-radius: 50%;
	position: relative;
}

.tg-user figure a {
	width: 45px;
	height: 45px;
	display: block;
	overflow: hidden;
	border-radius: 50%;
	background: #dce5f9;
}

.tg-user figure a img {
	display: block;
	overflow: hidden;
	border-radius: 50%;
}

.tg-userinfo {
	padding: 4px 0;
	overflow: hidden;
}

.tg-userinfo h4 {
	margin: 0;
	color: #262626;
	font-size: 16px;
	line-height: 20px;
}

.tg-userinfo h5 {
	margin: 0;
	color: #777;
	font-size: 14px;
	line-height: 17px;
}

.tg-loginstatus {
	right: 0;
	bottom: 0;
	width: 10px;
	height: 10px;
	display: none;
	position: absolute;
	border-radius: 50%;
	background: #6ac633;
	border: 2px solid #fff;
}

.tg-online .tg-loginstatus {
	display: block;
}

.tg-addcategorybox {
	width: 100%;
	float: left;
	padding: 0 15px 50px;
	clear: both;
	overflow: hidden;
	background: #fff;
	padding: 40px 25px;
	margin: -40px -25px 0;
}

.tg-addcategorybox .tg-sectiontitle {
	padding: 0 0 9px;
}

.tg-addcategorybox .tg-sectiontitle h2 {
	color: #353535;
	font-size: 22px;
	font-weight: 500;
	line-height: 24px;
}

.tg-addcategorybox .tg-sectiontitle h2 i,
.tg-addcategorybox .tg-sectiontitle h2 span {
	float: left;
}

.tg-addcategorybox .tg-sectiontitle h2 i svg {
	display: block;
}

.tg-addcategorybox .tg-sectiontitle h2 span {
	padding: 0 0 0 15px;
}

.tg-formaddcategory {
	padding: 15px 0 0;
}

.tg-btnandimage {
	width: 250px;
	float: right;
	padding: 0 0 0 20px;
	position: relative;
}

.tg-btnandimage figure {
	top: 0;
	right: 0;
	margin: 0;
	z-index: -1;
	height: 180px;
	overflow: hidden;
	position: absolute;
	border-radius: 5px;
	width: calc(100% - 20px);
	border: 1px solid #dbdbdb;
}

.tg-fieldsbox {
	overflow: hidden;
}

.tg-fieldsbox .form-control::-webkit-input-placeholder {
	color: #353535;
}

.tg-fieldsbox .form-control:-moz-placeholder {
	color: #353535;
}

.tg-fieldsbox .form-control::-moz-placeholder {
	color: #353535;
}

.tg-fieldsbox .form-control:-ms-input-placeholder {
	color: #353535;
}

.tg-fieldsbox .form-control::-ms-input-placeholder {
	color: #353535;
}

.tg-fieldsbox .form-control {
	height: 60px;
	padding: 15px;
	color: #353535;
	line-height: 28px;
	border-radius: 4px;
}

.tg-fieldsbox .form-control + .form-control {
	height: 180px;
	margin: 20px 0 0;
}

.tg-fileinput {
	opacity: 0;
	visibility: hidden;
}

.tg-btnandimage .tg-btn {
	width: 100%;
	float: left;
	margin: 20px 0 0;
	padding: 0 20px;
	border-radius: 4px;
	text-transform: capitalize;
}

.tg-fileuploadlabel {
	width: 100%;
	float: left;
	height: 180px;
	cursor: pointer;
	overflow: hidden;
	border-radius: 5px;
	border: 1px solid #dbdbdb;
}

.tg-btntoggleleftsidebar,
.tg-btntogglerightsidebar {
	top: 50%;
	width: 38px;
	z-index: 10;
	display: block;
	position: absolute;
	transform: translateY(-50%);
}

.tg-btntoggleleftsidebar {
	left: 0;
}

.tg-btntogglerightsidebar {
	right: 0;
}

.tg-btntoggleleftsidebar svg,
.tg-btntogglerightsidebar svg {
	width: 100%;
	display: block;
}

.tg-formsetting textarea.form-control {
	height: 319px;
	margin: 0;
}

.tg-loginarea,
.tg-signuparea {
	/* height: auto; */
}

.tg-loginarea .tg-main,
.tg-signuparea .tg-main {
	height: auto;
	padding: 150px 15px 248px;
}

.tg-resetpassword .tg-main {
	padding: 100px 15px 0;
}

.tg-formresetkey .form-group:nth-child(2) {
	text-align: center;
}

.tg-resetbackgound {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	background-size: cover;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50% 100%;
	background: url(../images/banner/img-04.jpg);
}

.tg-footersetting {
	padding: 0;
	background: none;
}

.tg-horizontalthemescrollbar.tg-appimggallery ul {
	/* width: auto;
	float: none; */
	overflow: auto;
	white-space: nowrap;
}

.tg-horizontalthemescrollbar.tg-appimggallery ul li {
	float: none;
	vertical-align: top;
	display: inline-block;
}

/* .tg-appimggallery.tg-horizontalthemescrollbar ul{
	list-style: none;
	transform: rotate(90deg);
	transform-origin: right top;
}
.tg-appimggallery.tg-horizontalthemescrollbar ul li{list-style-type: none;} */

.tg-dashboard .at-paddonlybottom {
	padding: 0 0 30px;
}

.tg-formpackageupload textarea.form-control {
	height: 280px;
}

.table.table-bordered thead {
	color: #fff;
}

.table.table-bordered {
	float: left;
	margin: 40px 0 0;
}

.table.table-bordered,
.table.table-bordered thead,
.table.table-bordered thead tr,
.table.table-bordered thead tr th {
	border: 0;
}

.tg-btn.nsg-users-btn {
	margin: 0;
}

.btn.at-themebtn {
	color: #fff;
	margin: 0 10px;
	padding: 6px 30px;
	border-radius: 17px;
	background: #fa3b52;
}
