 /* maxifjaved style custom */

 .nsg-dppreview {
 	margin-right: 5px;
 	border-radius: 50%;
 }

 a {
 	cursor: pointer;
 }

 .nsg-detailslider {
 	background: url(../images/banner/img-02.jpg) 0px 0px no-repeat scroll;
 	height: 420px;
 	overflow: hidden;
 	position: relative;
 	padding: 0 !important;
 }

 .nsg-detailslider {
 	height: 420px;
 	overflow: hidden;
 	position: relative;
 	padding: 0 !important;
 }

 .tg-home .nsg-detailslider .pogoSlider-slide {
 	height: 100%;
 	padding: 0 200px;
 }

 .nsg-detailslider .pogoSlider-slide {
 	height: 420px;
 	padding: 50px 0 0;
 	display: flex;
 	flex-direction: column;
 	justify-content: center;
 	align-content: center;
 }

 .tg-home .nsg-slidecontent {
 	width: 550px;
 	text-align: center;
 }

 .nsg-slidecontent {
 	width: 100%;
 	float: right;
 	text-align: center;
 }

 .tg-home .nsg-slidecontent h1 {
 	margin: 0;
 }

 .nsg-slidecontent h1 {
 	color: #353535;
 	margin: 0 0 60px;
 	font-size: 56px;
 	font-weight: bold;
 	line-height: 70px;
 }

 .nsg-slidecontent p {
 	font-size: 18px;
 	line-height: 21px;
 }

 .nsg-slidecontent .nsg-description {
 	padding: 50px 0;
 }

 .nsg-slidecontent .nsg-description p {
 	margin: 0;
 	color: #353535;
 	font-size: 20px;
 	line-height: 30px;
 }

 .nsg-floatright {
 	float: right;
 }

 .nsg-commenttrash {
 	display: flex;
 	cursor: pointer;
 	border: 0;
 	background: transparent;
 	outline: 0;
 	overflow: hidden;
 	float: right;
 }

 .nsg-commenttrash .icon {
 	position: relative;
 	background: #1D242B;
 	line-height: 30px;
 	width: 30px;
 	height: 30px;
 	text-align: center;
 	color: #fff;
 	font-size: 18px;
 	transition: .2s color;
 	border-radius: 2px;
 }

 .nsg-commenttrash .icon .fa {
 	width: 30px;
 	transition: .2s all;
 }

 .nsg-commenttrash .icon .fa-check {
 	color: #38B87C;
 }

 .nsg-commenttrash .icon:after {
 	content: ' ';
 	display: block;
 	position: absolute;
 	width: 5px;
 	height: 5px;
 	transform: rotate(45deg);
 	background: #1D242B;
 	top: 12.5px;
 	right: 1px;
 	transition: .2s right;
 	z-index: 1;
 }

 .nsg-commenttrash .text {
 	position: relative;
 	width: 0;
 	height: 30px;
 	overflow: hidden;
 	font-family: "Roboto", sans-serif;
 	background: #F34541;
 	text-align: center;
 	line-height: 30px;
 	color: #fff;
 	font-weight: 300;
 	transition: .2s all;
 	border-top-right-radius: 2px;
 	border-bottom-right-radius: 2px;
 }

 .nsg-commenttrash .text span {
 	width: 100%;
 	opacity: 0;
 	position: absolute;
 	top: -30px;
 	left: 50%;
 	transform: translateX(-50%);
 	transition: .3s all;
 }

 .nsg-commenttrash:hover .icon {
 	color: #F34541;
 	border-radius: 0;
 	border-top-left-radius: 2px;
 	border-bottom-left-radius: 2px;
 }

 .nsg-commenttrash:hover .icon:after {
 	right: -2px;
 }

 .nsg-commenttrash:hover .text {
 	width: 120px;
 }

 .nsg-commenttrash:hover .text span {
 	opacity: 1;
 	top: 0;
 }

 .nsg-commenttrash.confirm .icon .fa {
 	transform: translateY(-30px);
 }

 @-webkit-keyframes fadeInZoom {
 	0% {
 		opacity: 0;
 		transform: scale(0.7);
 	}
 	100% {
 		opacity: 1;
 		transform: scale(1);
 	}
 }

 @keyframes fadeInZoom {
 	0% {
 		opacity: 0;
 		transform: scale(0.7);
 	}
 	100% {
 		opacity: 1;
 		transform: scale(1);
 	}
 }

 .nsg-iconstyle {
 	width: 210px;
 }

 .nsg-fav {
 	color: white;
 	background: #fa3b52;
 }

 .nsg-fav:hover, .nsg-fav:focus, .nsg-fav:active {
 	background: #fff url(../images/bgbtn.jpg) repeat-x;
 }

 .nsg-category-list {
 	padding: 5px 10px 10px;
 	background: #f3f3f3;
 	border-radius: 2em;
 	text-align: center;
 }

 .nsg-category {
 	/* color: #fff!important; */
 	font-size: 1.6rem;
 	padding: 1rem;
 	/* padding-bottom: 1rem; */
 	white-space: nowrap;
 	/* margin-right: 7px; */
 	margin: 0.3rem;
 	display: inline-block;
 	border: 1px solid;
 	border-radius: 50px;
 	background: #fff;
 }

 .nsg-category-active, .nsg-category-active:hover, .nsg-category-active:focus, .nsg-category-active:active {
 	background: #fa3b52;
 	color: #fff;
 }

 .nsg-category-reset {
 	display: block;
 	margin-top: 1.3rem;
 	font-size: 1.6rem;
 	padding: 1rem;
 	white-space: nowrap;
 	border: 1px solid;
 	border-radius: 50px;
 	background: #fff;
 }

 .nsg-fileinput {}

 .nsg-fileinput input {}

 .nsg-filepreview-container {
 	margin: 0;
 	padding: 5px;
 }

 .nsg-filepreview-container img {
 	margin: 5px;
 	width: 200px;
 	height: auto;
 	border-radius: 5px;
 	border-radius: 5px;
 	background: #fff;
 	vertical-align: top;
 	display: inline-block;
 	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
 }

 .nsg-users-btn {
 	margin: 0.5em;
 }