/*==========================================================================================
								Primery Theme Colors										
==========================================================================================*/
/*=============================================
			Theme Text Color
=============================================*/
a,
p a,
p a:hover,
a:hover,
/* a:focus, */
/* a:active, */
.tg-stars span:after,
.tg-themecolor,
.tg-appholder:hover .tg-btnaddtowishlist,
.tg-liked,
.tg-appratingandcounts span + span,
.tg-pagination ul li a


{ color: #fa3b52; }
/*=============================================
Theme Background Color
=============================================*/
.tg-btn:hover,
.tg-dropdowarrow,
.navbar-toggle,
.tg-btn,
.tg-navigation ul li a:before,
.tg-appholder:hover .tg-apptag,
.tg-appsgridsvthree .tg-btnviewmore:hover,
.tg-largesingleapp figure figcaption,
.tg-appsgridsvfour .tg-appimg:before,
.tg-appsgridsvfour .tg-apptag,
.tg-appsgridsvseven .tg-smbtn,
.tg-btnaddtowishlist i,
.tg-appreviewshead span,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.tg-widgettitle,
.tg-pagination ul li:hover a,
.tg-pagination ul li.active a,
.table.table-bordered thead{ background:#fa3b52; }
/* { background:#fa3b52; } */

.Popup_at-popuphead__14Auc{background:#fa3b52 !important;}
/*=============================================
			Theme Border Color
=============================================*/
input:focus,
.select select:focus,
.form-control:focus


{ border-color: #fa3b52; }