/**
 * === z-index ===
 *
 * pogoSlider-progressBar =    6
 * pogoSlider-dir-btn     =   10
 * pogoSlider-nav         =   10
 * pogoSlider-loading     =   11
 * 
 */

.pogoSlider {
	width: 100%;
	height: 0;
	padding-bottom: 48%;
	position: relative;
	overflow: hidden;
}

#column-left .pogoSlider,
#column-right .pogoSlider,
#content .pogoSlider {
	margin-bottom: 20px;
}

.pogoSlider--banner .pogoSlider-slide {
	ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

.pogoSlider-slide {
	width: 100%;
	height: 100%;
	position: absolute;
	background-size: cover;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	perspective: 1300px;
	overflow: hidden;
}

.pogoSlider-loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #eaeaea;
	z-index: 11;
}

.pogoSlider-loading-icon {
	position: absolute;
	top: 50%;
	margin-top: -18px;
	left: 50%;
	margin-left: -18px;
	width: 36px;
	height: 36px;
	background-image: url(data:image/gif;base64,R0lGODlhJAAkAPIAAJmZmczMzObm5vLy8gAAAAAAAAAAAAAAACH5BA0KAAQAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAJAAkAAADfQi6DPQwyhmbpTgT27Sv3PKNoTh6pXKi6aqljovB8tzWE43vfH8GwKAw4HsMj0XCcZhcCptOIDQ6dVaXV2QxKk16vzWBeEwWPAbotHpUbp/VcHab/Ian5XNx3T7A5/d2fnOAcR95Y4RrhodmBHx3i4eJkB6MjY9ogm6OmCMJACH5BA0KAAQALAAAAAAkACQAAAN+GLocRCDK+aq1Lb/Jwf1P1mydBH4iQ5beiaXKWrovLHd0BccQ2+Y7R4+VC+1unCIhiKQUmcOZckqtWl2CrHYruIK4YO8HzBVfyFuzBa1VV9hZ9wPeldPlhLsdju/7vQOBgoMDeISHhoeDiYqBjI2PipGIco2Ck4SYi5WWhXIJACH5BA0KAAQALAAAAAAkACQAAAN7KLosRCHK+YC9GLT9pg9Vlm1N90mheJGMeYKEirGLe6YyrdgfruoOyAsmW+l4Hp8IiKTEipqj8KUcSYdE6GPL7Xq/4LB4/B2Yz+gBeZ1ur8nt9HscR8/F9fM9nDfvwX1qf16Bg4R9hl2FiYyNjo+QkZKTlJWWl5iZmpoJACH5BA0KAAQALAAAAAAkACQAAAN8OLo8RCLK+YK9OLT9phdVlm1N90mheJGMeYKEirGLe6YyrdgfruoOyAsmW+l4Hp8IiKTEipqj8KUcSYdE6GPL7Xq/4LB4TC5/Aei0GmAer99t8XsdD8/VdfA9nT/v2X1df4CBW4OFXIeID4qLjo+QkZKTlJWWl5iZmptxCQAh+QQNCgAEACwAAAAAJAAkAAADfUi6vPMwKkGrFS2ryMe8l5Z1EgGGIkNC30ml6uqZ7gtvcuve+KqfPIJsVrPdhj9QEEmrLXPNXXBKrVqvi4B2yw1gM93wtxHujhll7jmb1q4Vbfc77p3H3wQ6Xo/v+68AgYKDAHiEh4aHg4mKgYyNj4qRiG+NgpOEmIuVlgAJACH5BA0KAAQALAAAAAAkACQAAAN8SLrcziPKOZ69hGrMoZ5d+IEhN0qleVbptbLt88bWTDv27Z167/8cgXBIFAAJxeQxWVwyh86nESgVRp9XZlZJrR6/4AdgTC4rAui0OhAquwHn9br9JsflaXodTsCr9XV3foBvgniEboZyiGZ9fmiMdo6PkWOKcx17lpN+CQAh+QQNCgAEACwAAAAAJAAkAAADd0i63P4wykmrvTjrzbv/YCiOZGmSQ6quQ8i+7rvGckrXt5zDYK3uLODsRIQAjsgkQBFoOp8KgXRKFSivzKc2Wq1elVmtk9udfpNhcYBctp6PaTG7/IYT1GNC21yPb/V7bm9+UIB7dUt3eGuGbYiEeYGCZ5BNc10JACH5BA0KAAQALAAAAAAkACQAAAN3SLrc/jDKSau9OOvNu/9gKI5kaS5Aqq5AyL7uu8ZySte3nMNgre4s4OxELAaOyGRAIWg6n4qBdEodKK/MpzZarV6VWa2T251+k2GxgFy2no9pMbv8hhPUY0LbXI9v9Xtub35QgHt1S3d4a4ZtiIR5gYJnkE1zXQkAOw==);
}

.pogoSlider-slide-element {
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
}

.m-pogoSlider .pogoSlider-slide-element {
	position: absolute;
	margin: 0;
	box-sizing: border-box;
}

.pogoSlider-progressBar {
	position: absolute;
	width: 100%;
	height: 5px;
	top: 0;
	left: 0%;
	background: #fff; /* fallback */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
	filter: alpha(opacity=20);
	background: rgba(255,255,255,0.2);
	z-index: 6;
}

.pogoSlider-progressBar-duration {
	position: absolute;
	height: 100%;
	left: 0;
	width: 0%;
	background: #fff; /* fallback */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
	filter: alpha(opacity=40);
	background: rgba(255,255,255,0.4);
}

.pogoSlider-dir-btn {
	position: absolute;
	z-index: 10;
	background: none;
	width: 20px;
	height: 20px;
	border-color: #eaeaea;
	border-color: rgba(255,255,255,0.7);
	border-style: solid;
	transform: rotate(45deg);
	cursor: pointer;
}

.pogoSlider-dir-btn:hover {
	border-color: #fff;
}

.pogoSlider-dir-btn:focus {
	outline: none;
}

.pogoSlider--dirTopLeft .pogoSlider-dir-btn--prev,
.pogoSlider--dirTopRight .pogoSlider-dir-btn--prev,
.pogoSlider--dirBottomLeft .pogoSlider-dir-btn--prev,
.pogoSlider--dirBottomRight .pogoSlider-dir-btn--prev,
.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn--prev {
	border-width: 0 0 3px 3px;
}

.pogoSlider--dirTopLeft .pogoSlider-dir-btn--next,
.pogoSlider--dirTopRight .pogoSlider-dir-btn--next,
.pogoSlider--dirBottomLeft .pogoSlider-dir-btn--next,
.pogoSlider--dirBottomRight .pogoSlider-dir-btn--next,
.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn--next {
	border-width: 3px 3px 0 0;
	
}

.pogoSlider--dirCenterVertical .pogoSlider-dir-btn--next {
	border-width: 3px 0 0 3px;
}

.pogoSlider--dirCenterVertical .pogoSlider-dir-btn--prev {
	border-width: 0 3px 3px 0;
}

.pogoSlider--dirTopLeft .pogoSlider-dir-btn,
.pogoSlider--dirTopRight .pogoSlider-dir-btn,
.pogoSlider--dirCenterVertical .pogoSlider-dir-btn--next {
	top: 14px;
}

.pogoSlider--dirTopLeft .pogoSlider-dir-btn--prev,
.pogoSlider--dirBottomLeft .pogoSlider-dir-btn--prev,
.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn--prev   {
	left: 14px;
}

.pogoSlider--dirTopLeft .pogoSlider-dir-btn--next,
.pogoSlider--dirBottomLeft .pogoSlider-dir-btn--next {
	left: 50px;
}

.pogoSlider--dirTopRight .pogoSlider-dir-btn--prev,
.pogoSlider--dirBottomRight .pogoSlider-dir-btn--prev {
	right: 50px;
}

.pogoSlider--dirTopRight .pogoSlider-dir-btn--next,
.pogoSlider--dirBottomRight .pogoSlider-dir-btn--next,
.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn--next {
	right: 14px;
}

.pogoSlider--dirBottomLeft .pogoSlider-dir-btn,
.pogoSlider--dirBottomRight .pogoSlider-dir-btn,
.pogoSlider--dirCenterVertical .pogoSlider-dir-btn--prev {
	bottom: 14px;
}

.pogoSlider--dirCenterVertical .pogoSlider-dir-btn {
	left: 50%;
	margin-left: -10px
}

.pogoSlider--dirCenterHorizontal .pogoSlider-dir-btn {
	top: 50%;
	margin-top: -10px;
}

.pogoSlider-nav {
	position: absolute;
	padding: 0;
	margin: 0;
	z-index: 10;
}

.pogoSlider--navTop .pogoSlider-nav,
.pogoSlider--navBottom .pogoSlider-nav {
	left: 50%;
	transform: translateX(-50%);
}

.pogoSlider--navTop .pogoSlider-nav {
	top: 10px;
}

.pogoSlider--navBottom .pogoSlider-nav {
	bottom: 10px;
}

.pogoSlider--navTop .pogoSlider-nav li,
.pogoSlider--navBottom .pogoSlider-nav li {
	display: inline;
	margin: 0 6px;
}

.pogoSlider--navLeft .pogoSlider-nav,
.pogoSlider--navRight .pogoSlider-nav {
	top: 50%;
	transform: translateY(-50%);
}

.pogoSlider--navLeft .pogoSlider-nav {
	left: 10px;
}

.pogoSlider--navRight .pogoSlider-nav {
	right: 10px;
}

.pogoSlider--navLeft .pogoSlider-nav li,
.pogoSlider--navRight .pogoSlider-nav li {
	display: block;
	margin: 6px 0;
}

.pogoSlider-nav-btn {
	background: #cccccc;
	background: rgba(255,255,255,0.5);
	border: none;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
	cursor: pointer;
}

.pogoSlider-nav-btn:focus {
	outline: none;
}

.pogoSlider-nav-btn--selected {
	background: #fff;
	box-shadow: 0 1px 1px rgba(0,0,0,0.3);
}

.pogoSlider-slide-slice {
	overflow: hidden;
	position: absolute;
	background-size: cover;
}

.pogoSlider-slide-slice-inner {
	position: absolute;
	overflow: hidden;
}

/**
 * Element Animations
 */

/* Slide Down */

.pogoSlider-animation-slideDownIn {
	-webkit-animation-name: slideDownIn;
	        animation-name: slideDownIn;
}

@-webkit-keyframes slideDownIn {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideDownIn {
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.pogoSlider-animation-slideDownOut {
	-webkit-animation-name: slideDownOut;
	        animation-name: slideDownOut;
}

@-webkit-keyframes slideDownOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(50px);
		opacity: 0;
	}
}

@keyframes slideDownOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(50px);
		opacity: 0;
	}
}

/* Slide Up */

.pogoSlider-animation-slideUpIn {
	-webkit-animation-name: slideUpIn;
	        animation-name: slideUpIn;
}

@-webkit-keyframes slideUpIn {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slideUpIn {
	0% {
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.pogoSlider-animation-slideUpOut {
	-webkit-animation-name: slideUpOut;
	        animation-name: slideUpOut;
}

@-webkit-keyframes slideUpOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(50px);
		opacity: 0;
	}
}

@keyframes slideUpOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(50px);
		opacity: 0;
	}
}

/* Slide Right */

.pogoSlider-animation-slideRightIn {
	-webkit-animation-name: slideRightIn;
	        animation-name: slideRightIn;
}

.pogoSlider-animation-slideRightOut {
	-webkit-animation-name: slideRightOut;
	        animation-name: slideRightOut;
}

@-webkit-keyframes slideRightIn {
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideRightIn {
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slideRightOut {
	0% {
		transform: translateX(0);
		opacity: 1;	
	}

	100% {
		transform: translateX(50px);
		opacity: 0;
	}
}

@keyframes slideRightOut {
	0% {
		transform: translateX(0);
		opacity: 1;	
	}

	100% {
		transform: translateX(50px);
		opacity: 0;
	}
}

/* Slide Left */

.pogoSlider-animation-slideLeftIn {
	-webkit-animation-name: slideLeftIn;
	        animation-name: slideLeftIn;
}

.pogoSlider-animation-slideLeftOut {
	-webkit-animation-name: slideLeftOut;
	        animation-name: slideLeftOut;
}

@-webkit-keyframes slideLeftIn {
	0% {
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slideLeftIn {
	0% {
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes slideLeftOut {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(-50px);
		opacity: 0;
	}
}

@keyframes slideLeftOut {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(-50px);
		opacity: 0;
	}
}

/* Expand */

.pogoSlider-animation-expandIn {
	-webkit-animation-name: expandIn;
	        animation-name: expandIn;
}

.pogoSlider-animation-expandOut {
	-webkit-animation-name: expandOut;
	        animation-name: expandOut;
}

@-webkit-keyframes expandIn {
	0% {
		transform: scale(0.7);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes expandIn {
	0% {
		transform: scale(0.7);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes expandOut {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

@keyframes expandOut {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

/* Contract */

.pogoSlider-animation-contractIn {
	-webkit-animation-name: contractIn;
	        animation-name: contractIn;
}

.pogoSlider-animation-contractOut {
	-webkit-animation-name: contractOut;
	        animation-name: contractOut;
}

@-webkit-keyframes contractIn {
	0% {
		transform: scale(2);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes contractIn {
	0% {
		transform: scale(2);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes contractOut {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}

@keyframes contractOut {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}

/* Spin In */

.pogoSlider-animation-spinIn {
	-webkit-animation-name: spinIn;
	        animation-name: spinIn;
}

.pogoSlider-animation-spinOut {
	-webkit-animation-name: spinOut;
	        animation-name: spinOut;
}

@-webkit-keyframes spinIn {
	0% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(0) rotate(720deg);
	}

	100% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(1) rotate(0);
	}
}

@keyframes spinIn {
	0% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(0) rotate(720deg);
	}

	100% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(1) rotate(0);
	}
}

@-webkit-keyframes spinOut {
	0% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(1) rotate(0);
	}

	100% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(0) rotate(720deg);
	}
}

@keyframes spinOut {
	0% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(1) rotate(0);
	}

	100% {
		-webkit-backface-visibility: hidden;
		        backface-visibility: hidden;
		transform: scale(0) rotate(720deg);
	}
}
	
/* Side Fall */

.pogoSlider-animation-sideFallIn {
	transform-style: preserve-3d;
	-webkit-animation-name: sideFallIn;
	        animation-name: sideFallIn;
}

.pogoSlider-animation-sideFallOut {
	transform-style: preserve-3d;
	-webkit-animation-name: sideFallOut;
	        animation-name: sideFallOut;
}

@-webkit-keyframes sideFallIn {
	0% {
		transform: translate(30%) translateZ(600px) rotate(10deg); 
		opacity: 0;
	}

	100% {
		transform: translate(0) translateZ(0) rotate(0); 
		opacity: 1;
	}
}

@keyframes sideFallIn {
	0% {
		transform: translate(30%) translateZ(600px) rotate(10deg); 
		opacity: 0;
	}

	100% {
		transform: translate(0) translateZ(0) rotate(0); 
		opacity: 1;
	}
}

@-webkit-keyframes sideFallOut {
	0% {
		transform: translate(0) translateZ(0) rotate(0); 
		opacity: 1;
	}

	100% {
		transform: translate(30%) translateZ(600px) rotate(10deg); 
		opacity: 0;
	}
}

@keyframes sideFallOut {
	0% {
		transform: translate(0) translateZ(0) rotate(0); 
		opacity: 1;
	}

	100% {
		transform: translate(30%) translateZ(600px) rotate(10deg); 
		opacity: 0;
	}
}

/* 3D Horizontal Flip */

.pogoSlider-animation-horizontal3DFlipIn {
	transform-style: preserve-3d;
	-webkit-animation-name: horizontal3DFlipIn;
	        animation-name: horizontal3DFlipIn;
}

.pogoSlider-animation-horizontal3DFlipOut {
	transform-style: preserve-3d;
	-webkit-animation-name: horizontal3DFlipOut;
	        animation-name: horizontal3DFlipOut;
}

@-webkit-keyframes horizontal3DFlipIn {
	0% {
		transform: rotateY(-70deg);
		opacity: 0;
	}

	100% {
		transform: rotateY(0deg);
		opacity: 1;
	}
}

@keyframes horizontal3DFlipIn {
	0% {
		transform: rotateY(-70deg);
		opacity: 0;
	}

	100% {
		transform: rotateY(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes horizontal3DFlipOut {
	0% {
		transform: rotateY(0deg);
		opacity: 1;
	}

	100% {
		transform: rotateY(-70deg);
		opacity: 0;
	}
}

@keyframes horizontal3DFlipOut {
	0% {
		transform: rotateY(0deg);
		opacity: 1;
	}

	100% {
		transform: rotateY(-70deg);
		opacity: 0;
	}
}

/* 3D Vertical Flip */

.pogoSlider-animation-vertical3DFlipIn {
	transform-style: preserve-3d;
	-webkit-animation-name: vertical3DFlipIn;
	        animation-name: vertical3DFlipIn;
}

.pogoSlider-animation-vertical3DFlipOut {
	transform-style: preserve-3d;
	-webkit-animation-name: vertical3DFlipOut;
	        animation-name: vertical3DFlipOut;
}

@-webkit-keyframes vertical3DFlipIn {
	0% {
		transform: rotateX(-70deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0);
		opacity: 1;
	}
}

@keyframes vertical3DFlipIn {
	0% {
		transform: rotateX(-70deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0);
		opacity: 1;
	}
}

@-webkit-keyframes vertical3DFlipOut {
	0% {
		transform: rotateX(0);
		opacity: 1;
	}

	100% {
		transform: rotateX(-70deg);
		opacity: 0;
	}
}

@keyframes vertical3DFlipOut {
	0% {
		transform: rotateX(0);
		opacity: 1;
	}

	100% {
		transform: rotateX(-70deg);
		opacity: 0;
	}
}

/* 3d Pivot */

.pogoSlider-animation-3DPivotIn {
	transform-style: preserve-3d;
	-webkit-animation-name: pivotIn;
	        animation-name: pivotIn;
}

.pogoSlider-animation-3DPivotOut {
	transform-style: preserve-3d;
	-webkit-animation-name: pivotOut;
	        animation-name: pivotOut;
}

@-webkit-keyframes pivotIn {
	0% {
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
	}

	100% {
		transform: rotateX(0);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes pivotIn {
	0% {
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
	}

	100% {
		transform: rotateX(0);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@-webkit-keyframes pivotOut {
	0% {
		transform: rotateX(0);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
	}
}

@keyframes pivotOut {
	0% {
		transform: rotateX(0);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: rotateX(-60deg);
		transform-origin: 50% 0;
		opacity: 0;
	}
}

/* Roll Left */

.pogoSlider-animation-rollLeftIn {
	-webkit-animation-name: rollLeftIn;
	        animation-name: rollLeftIn;
}

.pogoSlider-animation-rollLeftOut {
	-webkit-animation-name: rollLeftOut;
	        animation-name: rollLeftOut;
}

@-webkit-keyframes rollLeftIn {
	0% {
		opacity: 0;
		transform: translateX(100%) rotate(120deg);
	}

	100% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}
}

@keyframes rollLeftIn {
	0% {
		opacity: 0;
		transform: translateX(100%) rotate(120deg);
	}

	100% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}
}

@-webkit-keyframes rollLeftOut {
	0% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}

	100% {
		opacity: 0;
		transform: translateX(-100%) rotate(-120deg);
	}
}

@keyframes rollLeftOut {
	0% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}

	100% {
		opacity: 0;
		transform: translateX(-100%) rotate(-120deg);
	}
}


/* Roll Right */

.pogoSlider-animation-rollRightIn {
	-webkit-animation-name: rollRightIn;
	        animation-name: rollRightIn;
}

.pogoSlider-animation-rollRightOut {
	-webkit-animation-name: rollRightOut;
	        animation-name: rollRightOut;
}

@-webkit-keyframes rollRightIn {
	0% {
		opacity: 0;
		transform: translateX(-100%) rotate(-120deg);
	}

	100% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}
}

@keyframes rollRightIn {
	0% {
		opacity: 0;
		transform: translateX(-100%) rotate(-120deg);
	}

	100% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}
}

@-webkit-keyframes rollRightOut {
	0% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}

	100% {
		opacity: 0;
		transform: translateX(100%) rotate(120deg);
	}
}

@keyframes rollRightOut {
	0% {
		opacity: 1;
		transform: translateX(0px) rotate(0deg);
	}

	100% {
		opacity: 0;
		transform: translateX(100%) rotate(120deg);
	}
}

/* Glide Left */

.pogoSlider-animation-glideLeftIn {
	-webkit-animation-name: glideLeftIn;
	        animation-name: glideLeftIn;
	-webkit-animation-timing-function: ease-out;
	        animation-timing-function: ease-out;
}

.pogoSlider-animation-glideLeftOut {
	-webkit-animation-name: glideLeftOut;
	        animation-name: glideLeftOut;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}

@-webkit-keyframes glideLeftIn {
	0% {
		transform: translateX(100%) skewX(-30deg);
		opacity: 0;
	}

	60% {
		transform: translateX(-20%) skewX(30deg);
		opacity: 1;
	}

	80% {
		transform: translateX(0%) skewX(-15deg);
		opacity: 1;
	}

	100% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}
}

@keyframes glideLeftIn {
	0% {
		transform: translateX(100%) skewX(-30deg);
		opacity: 0;
	}

	60% {
		transform: translateX(-20%) skewX(30deg);
		opacity: 1;
	}

	80% {
		transform: translateX(0%) skewX(-15deg);
		opacity: 1;
	}

	100% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes glideLeftOut {
	0% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}

	100% {
		transform: translateX(-100%) skewX(30deg);
		opacity: 0;
	}
}

@keyframes glideLeftOut {
	0% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}

	100% {
		transform: translateX(-100%) skewX(30deg);
		opacity: 0;
	}
}


/* Glide Right */

.pogoSlider-animation-glideRightIn {
	-webkit-animation-name: glideRightIn;
	        animation-name: glideRightIn;
	-webkit-animation-timing-function: ease-out;
	        animation-timing-function: ease-out;
}

.pogoSlider-animation-glideRightOut {
	-webkit-animation-name: glideRightOut;
	        animation-name: glideRightOut;
	-webkit-animation-timing-function: ease-in;
	        animation-timing-function: ease-in;
}

@-webkit-keyframes glideRightIn {
	0% {
		transform: translateX(-100%) skewX(30deg);
		opacity: 0;
	}

	60% {
		transform: translateX(20%) skewX(-30deg);
		opacity: 1;
	}

	80% {
		transform: translateX(0%) skewX(15deg);
		opacity: 1;
	}

	100% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}
}

@keyframes glideRightIn {
	0% {
		transform: translateX(-100%) skewX(30deg);
		opacity: 0;
	}

	60% {
		transform: translateX(20%) skewX(-30deg);
		opacity: 1;
	}

	80% {
		transform: translateX(0%) skewX(15deg);
		opacity: 1;
	}

	100% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes glideRightOut {
	0% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}

	100% {
		transform: translateX(100%) skewX(-30deg);
		opacity: 0;
	}
}

@keyframes glideRightOut {
	0% {
		transform: translateX(0%) skewX(0deg);
		opacity: 1;
	}

	100% {
		transform: translateX(100%) skewX(-30deg);
		opacity: 0;
	}
}

/* FlipX */

.pogoSlider-animation-flipXIn {
	-webkit-backface-visibility: visible !important;
	        backface-visibility: visible !important;
	-webkit-animation-name: flipXIn;
	        animation-name: flipXIn;
}

.pogoSlider-animation-flipXOut {
	-webkit-animation-name: flipXOut;
	        animation-name: flipXOut;
	-webkit-backface-visibility: visible !important;
	        backface-visibility: visible !important;
}

@-webkit-keyframes flipXIn {
	0% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotateX(-10deg);
	}

	70% {
		transform: perspective(400px) rotateX(10deg);
	}

	100% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}

@keyframes flipXIn {
	0% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotateX(-10deg);
	}

	70% {
		transform: perspective(400px) rotateX(10deg);
	}

	100% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes flipXOut {
	0% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}
}

@keyframes flipXOut {
	0% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0;
	}
}

/* FlipY */

.pogoSlider-animation-flipYIn {
	-webkit-backface-visibility: visible !important;
	        backface-visibility: visible !important;
	-webkit-animation-name: flipYIn;
	        animation-name: flipYIn;
}

.pogoSlider-animation-flipYOut {
	-webkit-backface-visibility: visible !important;
	        backface-visibility: visible !important;
	-webkit-animation-name: flipYOut;
	        animation-name: flipYOut;
}

@-webkit-keyframes flipYIn {
	0% {
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotateY(-10deg);
	}

	70% {
		transform: perspective(400px) rotateY(10deg);
	}

	100% {
		transform: perspective(400px) rotateY(0deg);
		opacity: 1;
	}
}

@keyframes flipYIn {
	0% {
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotateY(-10deg);
	}

	70% {
		transform: perspective(400px) rotateY(10deg);
	}

	100% {
		transform: perspective(400px) rotateY(0deg);
		opacity: 1;
	}
}

@-webkit-keyframes flipYOut {
	0% {
		transform: perspective(400px) rotateY(0deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}
}

@keyframes flipYOut {
	0% {
		transform: perspective(400px) rotateY(0deg);
		opacity: 1;
	}

	100% {
		transform: perspective(400px) rotateY(90deg);
		opacity: 0;
	}
}

/**
 * Slide Animations
 */

/* Fold Left */

.pogoSlider-animation-foldInLeft {
	-webkit-animation-name: foldInLeft;
	        animation-name: foldInLeft;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-origin: 0% 0% 0;
	transform-style: preserve-3d;
}

@-webkit-keyframes foldInLeft {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(-180deg);
	}
}

@keyframes foldInLeft {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(-180deg);
	}
}

.pogoSlider-animation-foldOutLeft {
	-webkit-animation-name: foldOutLeft;
	        animation-name: foldOutLeft;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-origin: 100% 100% 0;
	transform-style: preserve-3d;
}

@-webkit-keyframes foldOutLeft {
	0% {
		transform: rotateY(180deg);
	}

	100% {
		transform: rotateY(0deg);
	}
}

@keyframes foldOutLeft {
	0% {
		transform: rotateY(180deg);
	}

	100% {
		transform: rotateY(0deg);
	}
}

/* Fold Right */

.pogoSlider-animation-foldInRight {
	-webkit-animation-name: foldInRight;
	        animation-name: foldInRight;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-origin: 100% 100% 0;
	transform-style: preserve-3d;
}

@-webkit-keyframes foldInRight {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(180deg);
	}
}

@keyframes foldInRight {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(180deg);
	}
}

.pogoSlider-animation-foldOutRight {
	-webkit-animation-name: foldOutRight;
	        animation-name: foldOutRight;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	transform-origin: 0% 0% 0;
	transform-style: preserve-3d;
}

@-webkit-keyframes foldOutRight {
	0% {
		transform: rotateY(-180deg);
	}

	100% {
		transform: rotateY(0deg);
	}
}

@keyframes foldOutRight {
	0% {
		transform: rotateY(-180deg);
	}

	100% {
		transform: rotateY(0deg);
	}
}

/* Expand Reveal */

.pogoSlider-animation-expandReveal {
	-webkit-animation-name: expandReveal;
	        animation-name: expandReveal;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes expandReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}

@keyframes expandReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.3);
		opacity: 0;
	}
}

/* Shrink Reveal */

.pogoSlider-animation-shrinkReveal {
	-webkit-animation-name: shrinkReveal;
	        animation-name: shrinkReveal;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes shrinkReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}

@keyframes shrinkReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.5);
		opacity: 0;
	}
}

/* Blocks Reveal */

.pogoSlider-animation-blocksReveal {
	-webkit-animation-name: blocksReveal;
	        animation-name: blocksReveal;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes blocksReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.8);
		opacity: 0;
	}
}

@keyframes blocksReveal {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0.8);
		opacity: 0;
	}
}

/* Down */

.pogoSlider-animation-downIn {
	-webkit-animation-name: downIn;
	        animation-name: downIn;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes downIn {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes downIn {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(0);
	}
}

.pogoSlider-animation-downOut {
	-webkit-animation-name: downOut;
	        animation-name: downOut;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes downOut {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(100%);
	}
}

@keyframes downOut {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(100%);
	}
}

/* Up */

.pogoSlider-animation-upIn {
	-webkit-animation-name: upIn;
	        animation-name: upIn;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes upIn {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes upIn {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(0);
	}
}

.pogoSlider-animation-upOut {
	-webkit-animation-name: upOut;
	        animation-name: upOut;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes upOut {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

@keyframes upOut {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

/* Right */

.pogoSlider-animation-rightIn {
	-webkit-animation-name: rightIn;
	        animation-name: rightIn;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes rightIn {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightIn {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(0);
	}
}

.pogoSlider-animation-rightOut {
	-webkit-animation-name: rightOut;
	        animation-name: rightOut;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes rightOut {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes rightOut {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}

/* Left */

.pogoSlider-animation-leftIn {
	-webkit-animation-name: leftIn;
	        animation-name: leftIn;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes leftIn {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes leftIn {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(0);
	}
}

.pogoSlider-animation-leftOut {
	-webkit-animation-name: leftOut;
	        animation-name: leftOut;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes leftOut {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes leftOut {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-100%);
	}
}