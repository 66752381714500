.at-popupcontainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1000;
    position: fixed;
    background: rgba(0, 0, 0, 0.70);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    flex-direction: column;
    transition: all 600ms ease-in-out;
    transform: scale(0);
}

.at-openpopup {
    opacity: 1;
    transform: scale(1);
}

.at-sm {
    max-width: 355px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-md {
    max-width: 755px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-popupimg {
    margin: 0;
    width: 100%;
    float: left;
    background: #fff;
    position: relative;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}

.at-popupimg img {
    width: 100%;
    height: auto;
    opacity: 0.95;
    display: block;
    border-radius: 5px 5px 0 0;
}

.at-closepopup {
    top: 20px;
    z-index: 9;
    right: 20px;
    width: 11px;
    height: 11px;
    cursor: pointer;
    position: absolute;
}

.at-popupcontent {
    width: 100%;
    float: left;
    position: relative;
    padding: 75px 40px 35px;
}

.at-popupicon {
    left: 50%;
    top: -50px;
    width: 100px;
    height: 100px;
    display: block;
    background: #fff;
    line-height: 100px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    transform: translateX(-50%);
}

.at-popupicon img {
    border-radius: 50%;
    box-shadow: 0 3px 20px 0 rgba(1, 138, 205, 0.20);
}

.at-popupicon i {
    display: block;
    color: #ff0000;
    font-size: 60px;
    line-height: 100px;
}

.at-popupcontent h3 {
    width: 100%;
    float: left;
    color: #262626;
    font-size: 28px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    text-transform: none;
    font-family: 'Poppins', sans-serif;
}

.at-popupcontent em {
    clear: both;
    display: block;
    color: #454545;
    font-size: 14px;
    margin: 0 0 30px;
    line-height: 14px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
}

.at-btnarea {
    width: 100%;
    float: left;
    padding: 50px 0 0;
    text-align: center;
}

/*.at-popupcontent h3 {
    margin: 0 0 20px;
    text-align: left;
}*/