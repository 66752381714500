.at-popupcontainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1000;
    position: fixed;
    background: rgba(0, 0, 0, 0.70);
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-transition: all 600ms ease-in-out;
    -moz-transition: all 600ms ease-in-out;
    -ms-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.at-openpopup {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.at-forgotpasswordpopup {
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.11);
}

.at-popuphead {
    width: 100%;
    float: left;
    text-align: center;
    padding: 22px 20px;
    position: relative;
    background: #fa3b52;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.11);
}
.at-popuphead h3 {
    margin: 0;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.at-forgotcontent {
    width: 100%;
    float: left;
    padding: 50px;
    background: #fff;
}

.at-forgotcontent h3 {
    width: 100%;
    float: left;
    color: #262626;
    font-size: 14px;
    margin: 0 0 25px;
    line-height: 22px;
    text-align: center;
    text-transform: none;
    font-family: 'Poppins', sans-serif;
}

.at-btnarea .at-themebtn {
    width: 100%;
}