*:after,
*:before,
p a,
a,
.tg-socialicons li a,
.tg-roundicontext,
.tg-roundicontext .tg-usericonholder,
.sub-menu,
.mega-menu,
.at-sidebarwrapper,
.tg-navigation ul li,
.tg-navigation ul li a,
.tg-navigation ul li a:before,
.tg-apptag,
.tg-btnviewmore
{
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}