@media (max-width:1280px){
	.tg-appdetailmain{padding: 60px 15px 91px;}
	.tg-home .tg-homeslider {height: 700px;}
	.tg-dashboardcontent {padding: 0 180px;}
}
@media (max-width:1199px){
	.tg-widgetcontent ul li{width: 100%;}
	.tg-widgetcontent ul li a{
		display: block;
		padding: 1rem 1.5rem;
	}
	.tg-appdetailsidebar{
		float: none;
		width: auto;
		margin: 30px -15px 0;
	}
	.tg-appdetailsidebar .tg-appwidget {
		margin: 15px;
		min-width: 290px;
		width: calc(33.33% - 30px);
	}
}
@media (max-width:1080px){
	.tg-dashboardcontent {padding: 0 100px;}
}
@media (max-width:991px){
	.tg-home .tg-homeslider {height: 600px;}
	.tg-home .tg-homeslider .pogoSlider-slide {padding: 104px 50px 0 50px;}
	.tg-home .tg-slidecontent{
			width: 100%;
			text-align: center;
	}
	.tg-slidecontent .tg-description {padding: 25px 0;}
	.tg-widgetcontent ul li {width: 33.33%;}
	.tg-dashboardcontent {padding: 0;}
}
@media (max-width:989px){
	.tg-appdetailsidebar .tg-appwidget{
		width: calc(50% - 30px);
		min-width: auto;
	}
}
@media (max-width:800px){
	.tg-addnav ul li{padding: 0 10px;}
	.tg-addnav ul li:last-child{padding-right: 0;}
	.tg-home .tg-homeslider {height: 450px;}
	.tg-slidecontent h1 {
		font-size: 35px;
		line-height: 28px;
	}
	.tg-slidecontent .tg-description {padding: 15px 0;}
	.tg-navigation > ul > li {
		padding: 0 10px;
		margin: 0 0 55px;
	}
	.tg-app {width: 50%;}
	.tg-formnewsletter {padding: 0 15px;}
}
@media (max-height:650px){
	.tg-resetpassword{height: auto;}
	.tg-resetpassword .tg-main{
		height: auto;
		padding: 150px 15px 120px;
	}
}
@media (max-width:767px){
	.tg-header{padding: 15px 0;}
	.tg-dropdowarrow{display: block;}
	.tg-logo {
		width: 180px;
		float: none;
		margin: 0 auto 15px;
		display: block;
	}
	.tg-addnav{
		width: 100%;
		padding-top: 0;
	}
	.tg-addnav ul li{float: right;}
	.tg-addnav ul li:last-child{float: left;}
	.tg-nav{
		padding: 15px;
		overflow: initial;
	}
	.tg-navigation{
		top: 100%;
		left: 0;
		background: #fff;
		text-align: left;
		position: absolute;
	}
	.tg-navigation ul{width: 100%;}
	.tg-navigation ul li{
		width: 100%;
		float: left;
		display: block;
	}
	.sub-menu,
	.mega-menu{
		clear: both;
		float: left;
		display: none;
		position: static;
		width: 100% !important;
	}
	.tg-navigation>ul>li:before,
	.tg-navigation>ul>li:last-child:after{display: none;}
	.tg-home .tg-homeslider .pogoSlider-slide {padding: 120px 15px 0 15px;}
	.tg-footerbar{padding: 0 15px;}
	.tg-dashboardcontent {padding: 70px 0 0;}
	.tg-formarea .tg-formtheme {padding: 40px 20px 10px;}
	.tg-signuparea .tg-formtheme {padding: 40px 20px 40px;}
	.tg-apphead figure{
		width: 210px;
		float: none;
		margin: 0 auto;
	}
	.tg-appheadcontent{
		width: 100%;
		padding: 30px 0 0;
	}
}
@media (max-width:640px){
	.tg-widgetcontent ul li {width: 50%;}
	.tg-main {padding: 60px 15px 91px;}
}
@media (max-width:568px){
	.tg-app,
	.tg-copyright,
	.tg-fnav ul li,
	.tg-footerbar .tg-socialicons{width: 100%;}
	.tg-copyright,
	.tg-footerbar .tg-socialicons{
		padding-top: 0;
		text-align: center;
	}
	.tg-footerbar .tg-socialicons li{
		float: none;
		display: inline-block;
		vertical-align: middle;
	}
	.tg-appdetailsidebar .tg-appwidget{
		width: calc(100% - 30px);
		min-width: 290px;
	}
}
@media (max-width:480px){
	.tg-logo{
		margin: 0;
		z-index: 2;
		float: left;
		width: 130px;
		position: relative;
	}
	.tg-addnav ul{
		z-index: 1;
		position: relative;
		margin: -50px 0 0;
	}
	.tg-addnav ul li:last-child{
		width: 100%;
		padding: 20px 0 0;
	}
	.tg-slidecontent h1 {
		font-size: 25px;
		line-height: 20px;
	}
	.tg-widgetcontent ul li{width: 100%;}
	.tg-footerupcontent h3 {
		font-size: 22px;
		margin: 0 0 43px;
		line-height: 30px;
	}
	.tg-dashboardcontent {padding: 120px 0 0;}
	.tg-formsetting .tg-btn{
		padding: 0;
		width: 100%;
	}
	.tg-formlogin .tg-btn,
	.tg-formsignup .tg-btn{width: 100%;}
}
@media (max-width:479px){}
@media (max-width:320px){}